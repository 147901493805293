/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 17.03.2020.
 */
"use strict";


import { gsap } from "gsap/all";
import Events from "../fieldcode.events";
import * as Utils from "../utilities/Utilities";
import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";

class Header extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._navigationWrapper = this._element.querySelector('.header__navigation');
		this._navigation = this._navigationWrapper.querySelector('.navigation.navigation--primary');
		this._ctaWrapper = this._element.querySelector('.header__cta');
		this._cta = this._ctaWrapper.querySelector('.access-now');

		this._tlScroll = null;
		this._tl = null;
		this._tlEventCallback = null;
		this._tlCtaEventCallback = null;


		this._height = 0;
		this._lastScrollTop = 0;

		this._init();
	}

	//public

	//private

	_init() {
		this._element.dataset.init = true;
		this._height = Utils.Helper.getHeight(this._element, true);

		this._tlScroll = gsap.timeline({
			paused: true,
			onStart:() => {
				//header out of view
				this._element.classList.add('header--collapsed');
				Utils.DispatchEvent.dispatch(Events.header.out);
				this._app.lifecycle.visible.header = false;
			},
			onComplete: () => {

			},
			onReverseComplete: () => {
				//header in of view
				this._element.classList.remove('header--collapsed');
				Utils.DispatchEvent.dispatch(Events.header.in);
				this._app.lifecycle.visible.header = true;
			}
		});

		this._tlScroll
			.to(this._element, {
				ease: 'power2.in',
				opacity: 0,
				y: '-100%',
				duration: 0.3
			});

		this._tl = gsap.timeline({
			paused: true,
			onStart:() => {
				this._app.lifecycle.animating.header = true;
				this._app.lifecycle.visible.navigation = true;
				this._element.classList.add('header--fh');
				if (Utils.Helper.isFunction(this._tlEventCallback)) {
					this._tlEventCallback();
				}
			},
			onComplete: () => {
				this._element.classList.add('header--expanded');
				this._app.lifecycle.animating.header = false;
			},
			onReverseComplete: () => {
				this._app.lifecycle.animating.header = false;
				this._app.lifecycle.visible.navigation = false;
				this._element.classList.remove('header--fh');
				if (Utils.Helper.isFunction(this._tlEventCallback)) {
					this._tlEventCallback();
				}
			}
		});

		this._tl
			.set(this._navigationWrapper, { display: 'block', opacity: 1 })
			.set(this._navigation, { opacity: 0 })
			.to(this._element, {
				ease: 'power4.inOut',
				duration: 0.6,
				backgroundColor: 'rgba(218,211,208,1)',
				height: '100%'
				//height: Utils.Helper.getViewportHeight(true)
			})
			.fromTo(this._navigation, {
				opacity: 0,
				//y: -40
			}, {
				ease: 'power4.inOut',
				duration: 0.8,
				opacity: 1,
				//y: 0
			}, 0.3);

		this._tlCta = gsap.timeline({
			paused: true,
			onStart:() => {
				this._app.lifecycle.animating.header = true;
				this._app.lifecycle.visible.headerCta = true;
				this._element.classList.add('header--fh');
				if (Utils.Helper.isFunction(this._tlCtaEventCallback)) {
					this._tlCtaEventCallback();
				}
			},
			onComplete: () => {
				this._element.classList.add('header--expanded');
				this._app.lifecycle.animating.header = false;
			},
			onReverseComplete: () => {
				this._app.lifecycle.animating.header = false;
				this._app.lifecycle.visible.headerCta = false;
				this._element.classList.remove('header--fh');
				if (Utils.Helper.isFunction(this._tlCtaEventCallback)) {
					this._tlCtaEventCallback();
				}
			}
		});

		this._tlCta
			.set(this._ctaWrapper, { display: 'block', opacity: 1 })
			.set(this._cta, { opacity: 0 })
			.to(this._element, {
				ease: 'power4.inOut',
				duration: 0.6,
				backgroundColor: 'rgba(218,211,208,1)',
				height: '100%'
			})
			.fromTo(this._cta, {
				opacity: 0,
				//y: -40
			}, {
				ease: 'power4.inOut',
				duration: 0.8,
				opacity: 1,
				//y: 0
			}, 0.3);


		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		window.addEventListener(Events.preload.finished, this._onPreloadFinished.bind(this));
		window.addEventListener(Events.layout.scroll.did, this._onOptimiziedScroll.bind(this));
		window.addEventListener(Events.navigation.do.show, this._onDoShowNavigation.bind(this));
		window.addEventListener(Events.navigation.do.hide, this._onDoHideNavigation.bind(this));
		window.addEventListener(Events.header.do.show.cta, this._onDoShowCta.bind(this));
		window.addEventListener(Events.header.do.hide.cta, this._onDoHideCta.bind(this));
	}

	_onPreloadFinished(evt) {
		this._onOptimiziedScroll(null);
		this._element.classList.remove('header--collapsed');
	}

	_onOptimiziedScroll(evt) {
		let currentScrollTop = evt ? evt.detail.scrollTop : 0, heightReference;



		if (device == 'desktop') {
			if (this._lastScrollTop < currentScrollTop && currentScrollTop > (this._height * 2)) {
				//this._element.classList.add('header--collapsed');
				//Utils.DispatchEvent.dispatch(Events.header.out);
				this._hideHeader();
			} else if (this._lastScrollTop > currentScrollTop && !(currentScrollTop <= this._height)) {
				//this._element.classList.remove('header--collapsed');
				//Utils.DispatchEvent.dispatch(Events.header.in);
				this._showHeader();
			}
		}

		this._lastScrollTop = currentScrollTop;
	}

	/**
	 *
	 * @returns {Promise}
	 * @private
	 */
	_hideHeader() {
		return new Promise((resolve) => {
			this._tlScroll.pause();
			this._tlScroll.play().then(() => {
				if (!this._element.classList.contains('header--scrolled')) {
					this._element.classList.add('header--scrolled');
				}
				resolve(true);
			});
		})
	}

	/**
	 *
	 * @returns {Promise}
	 * @private
	 */
	_showHeader() {
		return new Promise((resolve) => {
			this._tlScroll.pause();
			this._tlScroll.reverse().then(() => {
				resolve(true);
			});
		})
	}

	_onDoShowNavigation(evt) {
		if (this._app.lifecycle.animating.header) return;
		this._app.lifecycle.visible.navigation = true;
		this._tlEventCallback = evt.detail.action;
		this._tl.timeScale( 1.3 );
		this._tl.play();

	}
	_onDoHideNavigation(evt) {
		if (this._app.lifecycle.animating.header) return;
		this._app.lifecycle.visible.navigation = false;
		this._tlEventCallback = evt.detail.action;
		this._tl.timeScale( 2 );
		this._element.classList.remove('header--expanded');
		this._tl.reverse();
	}

	async _onDoShowCta(evt) {
		if (this._app.lifecycle.animating.header) return;
		if (this._app.lifecycle.visible.header == false) {
			await this._showHeader();
		}
		this._app.lifecycle.visible.accessnow = true;
		this._tlCtaEventCallback = evt.detail.action;
		this._tlCta.timeScale( 1.3 );
		this._tlCta.play();

	}
	async _onDoHideCta(evt) {
		if (this._app.lifecycle.animating.header) return;
		this._app.lifecycle.visible.accessnow = false;
		this._tlCtaEventCallback = evt.detail.action;
		this._tlCta.timeScale( 2 );
		this._element.classList.remove('header--expanded');
		this._tlCta.reverse();
	}
}

export default Header;
