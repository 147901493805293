/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 11.03.2021.
 */
"use strict";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";

class PricingTable extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._select = this._element.querySelector('.pricing-table__select SELECT');

		this._init();

	}

	_init() {
		this._element.dataset.init = true;
		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {

		this._select.addEventListener('change', this._onChange.bind(this));
	}

	_onChange(evt) {
		this._element.dataset.currency = this._select.value;
	}
}

export default PricingTable;