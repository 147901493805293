/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 20.03.2020.
 */


"use strict";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import Pristine from "pristinejs/src/pristine";
import Dropzone from 'dropzone/dist/dropzone';

class Form extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this.xhr = false;
		this.lsItemOnSubmit;

		this._validator = null;
		this._localStore = window.localStorage;

		this._grTokenField = this._element.querySelector('[name="grtoken"]');
		this._submitButton = this._element.querySelector('button[type="submit"]');
		this._identId = null;

		this._hasUpload = false;
		this._uploadContainer = this._element.querySelector('.form__upload');
		this._uploadHandler = null;


		this._init();

	}

	getValidator() {
		return this._validator;
	}

	_init() {
		this._element.dataset.init = true;
		this.xhr = this._element.dataset.xhr == 'true' ? true : false;
		this.lsItemOnSubmit = this._element.dataset.formLs;
		this._identId = this._element.dataset.ident ? parseInt(this._element.dataset.ident) : null;

		if (this._uploadContainer) {
			this._hasUpload = true;
			this._initUploadContainer();
		}

		if (this._localStore && this._identId != null) {
			if (this._localStore.getItem('fs-' + this._identId)) {
				this._submitButton.setAttribute('disabled', 'disabled');
			}
		}

		this._initValidators();
		this._attachEventsAndListeners();
	}

	_initUploadContainer() {
		const previewNode = this._uploadContainer.querySelector('.dz-preview-template');
		const previewTemplate = previewNode.innerHTML;
		previewNode.parentNode.removeChild(previewNode);

		this._uploadHandler = new Dropzone(this._element, {
			url: this._element.getAttribute('action'),
			autoProcessQueue: false,
			paramName: 'files',
			uploadMultiple: true,
			chunking: false,
			maxFiles: 10,
			maxFilesize: 10,
			parallelUploads: 10,
			addRemoveLinks: true,
			acceptedFiles: '.jpg,.jpeg,.png,.pdf,.doc,.docx',
			createImageThumbnails: false,
			clickable: this._uploadContainer.querySelector('.form__upload-zone'),
			previewsContainer: this._uploadContainer.querySelector('.form__upload-preview'),
			previewTemplate: previewTemplate,
			init: function() {
				this.on('sendingmultiple', (file, xhr, formData) => {
					// Gets triggered when the form is actually being sent.
					// Hide the success button or the complete form.
					console.log("sending multipl", file, xhr, formData);
					this.element.classList.add('form--sending');
				});

				this.on('successmultiple', (files, response) => {
					const json = response;
					//var json = JSON.parse(response);
					console.log("sucess mutiple", files, response);
					if (json.target) {
						document.location.href = json.target;
					}

				});

				this.on('errormultiple',(files, response) => {;
					this.element.classList.remove('form--sending');

					for(var i = 0; i < files.length; i++) {
						if (files[i].status == 'error') {
							this.removeFile(files[i]);
						}
					}
					console.log("error mutiple", files, response);
				});
			}
		})
	}

	_initValidators() {
		this._validator = new Pristine(this._element, {
			classTo: 'form__group',
			errorClass: 'form__group--has-error',
			successClass: 'form__group--has-success',
			//errotTextParent: false
			errorTextParent: 'form__group',
			errorTextTag: 'div',
			errorTextClass: 'tooltip tooltip--half'
		});
	}

	_attachEventsAndListeners() {
		this._element.addEventListener('submit', this._onSubmit.bind(this));
	}

	_onSubmit(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		this._checkRequiredBy();

		this._submitButton.setAttribute('disabled', 'disabled');

		if (this._validator.validate()) {

			if (this._localStore && this.lsItemOnSubmit) {
				this._localStore.setItem(this.lsItemOnSubmit, 'true');
			}

			if (typeof grecaptcha !== 'undefined' && grecaptcha && this._grTokenField) {
				grecaptcha.ready(() => {
					grecaptcha.execute(window.recaptchaKey, {action: 'submit'}).then((token) => {
						// Add your logic to submit to your backend server here.
						this._grTokenField.value = token;
						this._doSubmit();
					});
				});
			}
			else {
				this._doSubmit();
			}
		}
		else {
			this._submitButton.removeAttribute('disabled');
			console.log("error", this._validator.getErrors());
		}
	}

	_doSubmit() {

		if (this._localStore && this._identId != null) {
			this._localStore.setItem('fs-' + this._identId, 'true');
		}

		if (this._hasUpload) {
			if (this._uploadHandler.getQueuedFiles().length > 0) {
				this._uploadHandler.processQueue();
			} else {

				this._element.submit();
			}
		} else {
			this._element.submit();
		}
	}

	_checkRequiredBy() {
		let rFields = this._element.querySelectorAll('[data-req-by]'), cField, hasChanged = false;
		rFields.forEach((item, idx) => {
			cField = this._element.querySelector('[name="'+item.dataset.reqBy+'"]');
			if (cField.checked && !item.hasAttribute('required')) {
				item.setAttribute('required', '');
				hasChanged = true;
			}
			else if (!cField.checked && item.hasAttribute('required')) {
				item.removeAttribute('required');
				hasChanged = true;
			}
		});

		if (this._validator) {
			this._validator.destroy();
			this._initValidators();
			if (hasChanged) {
				this._validator.validate();
			}
		}
	}
}

export default Form;