/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 23.03.2020.
 */
"use strict";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import Events from "../fieldcode.events";
import * as Utils from "../utilities/Utilities";
import DKPagination from "../classes/Pagination";

import Swiper from 'swiper';
import { Pagination, EffectFade } from 'swiper/modules';

class Tabs extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._sliderWrapper = this._element.querySelector('.tabs__items');
		this._paginationElement = this._element.querySelector('.tabs__pagination');
		this._pagination = null;
		this._autoplay = 0;
		this._adaptiveHeight = false;

		this._swiper = null;
		this._swiperOptions = {}

		if (device != 'phone') {
			this._init();
		}
	}

	//public

	//private

	_init() {
		let initialIndex = 0, pageDots = false;

		this._element.dataset.init = true;
		this._app.handlers.intersection.attach(this._element);

		if (this._sliderWrapper) {
			if (this._sliderWrapper.dataset.autoplay) {
				this._autoplay = parseInt(this._sliderWrapper.dataset.autoplay);
			}
			//needed?
			if (this._sliderWrapper.dataset.adaptiveHeight) {
				this._adaptiveHeight = this._sliderWrapper.dataset.adaptiveHeight === 'true' ? true : false;
			}

		}

		this._swiperOptions = {...this._swiperOptions, ...{
			modules: [ Pagination, EffectFade ],
			autoHeight: this._adaptiveHeight,
			createElements: true,
			freeMode: false,
			loop: false,
			initialSlide: initialIndex,
			autoplay: (!this._autoplay || this._autoplay <= 0) ? false : this._autoplay,
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			}
		}};

		this._swiper = new Swiper(this._sliderWrapper, this._swiperOptions);

		if (this._paginationElement) {
			this._pagination = new DKPagination(this._paginationElement, this._element, this._app);
			this._pagination.setAdditionalActiveClass('button--active');
			this._pagination.switchTo(initialIndex);
		}

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		if (this._pagination && this._swiper) {
			this._element.addEventListener(Events.pagination.clicked, this._onPaginationItemClicked.bind(this));
			this._swiper.on('slideChange', this._onSlideChange.bind(this));
		}
	}

	_goto(index) {
		if (this._swiper) {
			this._swiper.slideTo(index);
		}
	}

	_onPaginationItemClicked(evt) {
		let idx = evt.detail.idx;

		if (device == 'phone') {
			window.scrollTo({
				top: Utils.Helper.getOffset(this._element).top - 50,
				left: 0,
				behavior: 'smooth'
			});
		}

		this._goto(idx);
	}

	_onSlideChange(swiper) {
		if(this._pagination) {
			this._pagination.switchTo(swiper.activeIndex);
		}
	}
}

export default Tabs;
