/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 06.04.2020.
 */


"use strict";

import { gsap } from "gsap/all";
import delegate from "es6-delegate/delegate";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import Events from "../fieldcode.events";


class Flash extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);
		this._tl = null;
		this._init();
	}

	//public

	//get/set

	//private

	_init() {
		this._element.dataset.init = true;

		this._tl = gsap.timeline({
			paused: true,
			onStart: () => {
			},
			onComplete: () => {

				this._element.remove();
			}
		});

		this._tl
			.to(this._element, {
				ease: 'strong2.in',
				opacity: 0,
				y: '-50%',
				duration: 0.3
			});

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		delegate('click', '[data-flash-close]', this._onClickClose.bind(this));
	}

	_onClickClose(evt, element) {
		if (evt) evt.preventDefault();
		this._tl.play();
	}
}

export default Flash;