/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 30.07.2020.
 */

"use strict";

import { gsap } from "gsap/all";
import delegate from "es6-delegate/delegate";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import Events from "../fieldcode.events";


class Datatable extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		console.log("element",this._element);

		this._datatableHeader = this._element.querySelector('.datatable__header');
		this._datatableBody = this._element.querySelector('.datatable__body');
		this._datatableMore = this._element.querySelector('.datatable__more');
		this._datatableInput = this._element.querySelector('.datatable__input');
		this._data = null;
		this._filteredData = null;
		this._visibleRowCount = parseInt(this._element.dataset.initialCount) || 8;
		console.log(this._visibleRowCount, "IVC", this._element.dataset.initialCount);

		this._tl = null;
		this._init();
	}

	//public

	//get/set

	//private

	_init() {
		this._element.dataset.init = true;
		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		window.addEventListener(Events.preload.finished, this._onPreloadFinished.bind(this));
		delegate('click', this._datatableMore, this._onClickMore.bind(this));
		delegate('keyup', this._datatableInput, this._onKeyUp.bind(this));

	}

	_fillTable() {
		let line = null;

		console.log("fill table", this._visibleRowCount);

		if ( this._datatableBody && this._data) {
			this._datatableBody.innerHTML = '';
			this._filteredData.slice(0, this._visibleRowCount).map(dataRow => {
				line = this._createRow(dataRow.name, dataRow.invoiceCurrency);
				this._datatableBody.appendChild(line);
			});
		}
	}

	_createRow(left, right) {
		let     row = document.createElement('div'),
			colLeft = document.createElement('div'),
			colRight = document.createElement('div');

		row.classList.add('datatable__row');
		colLeft.classList.add('datatable__column');
		colRight.classList.add('datatable__column');

		colLeft.textContent = left;
		colRight.textContent = right;

		row.appendChild(colLeft);
		row.appendChild(colRight);

		return row;
	}

	_onKeyUp(evt) {
		let inputData = this._datatableInput.value.toUpperCase();
		let tempFilteredData = [];
		if (inputData.length > 0) {
			this._data.map(dataRow => {
				if (dataRow.name.toUpperCase().indexOf(inputData) > -1) {
					tempFilteredData.push(dataRow);
				}
			});

			if (tempFilteredData.length > 0) {
				this._filteredData = tempFilteredData;
				this._fillTable();
			}
		}
		else {
			this._filteredData = this._data;
			this._fillTable();
		}


	}

	_onClickMore(evt) {
		if (evt) evt.preventDefault();
		this._visibleRowCount = this._data.length;
		this._datatableMore.classList.remove('datatable__more--visible');
		this._fillTable();
	}

	_onPreloadFinished(evt) {
		fetch('/accessnow/get/countries', {
			method: 'GET',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json'
			},
			redirect: 'follow',
			referrerPolicy: 'no-referrer',
			body: null
		})
			.then(response => response.json())
			.then(json => {

				if (!json.error) {
					this._data = json.data;
					this._filteredData = this._data;
					this._fillTable();

					if (this._visibleRowCount < this._data.length) {
						this._datatableMore.classList.add('datatable__more--visible');
					}
				}
				else {
					throw new Error(json.error);
				}
			})
			.catch((error) => {
				throw new Error(error);
			});
	}
}

export default Datatable;