/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 01.10.2019.
 */

/**
 * Only for code completion ....
 * @type {{layout: {mutation: {oberserved: string}, scroll: {did: string}, resize: {did: string}}, general: {}, preload: {finished: string, begin: string, register: string}}}
 */
const Events = {
	general: {
		scroll: 'throttledScroll',
		resize: 'throttledResize',
		instance: {
			get: 'general:instance:get'
		}
	},
	preload: {
		begin: 'preload:begin',
		finished: 'preload:finished',
		register: 'preload:register',
		unregister: 'preload:unregister',
		xhr: 'preload:xhr'
	},
	layout: {
		dimension: {
			check: 'layout:dimension:check'
		},
		resize: {
			did: 'layout:resize:did'
		},
		scroll: {
			did: 'layout:scroll:did'
		},
		mutation: {
			oberserved: 'layout:mutation:oberserver'
		}
	},
	navigation: {
		do: {
			show: 'navigation:do:show',
			hide: 'navigation:do:hide'
		},
		show: {
			begin: 'navigation:show:begin',
			finished: 'navigation:show:finished'
		},
		hide: {
			begin: 'navigation:hide:begin',
			finished: 'navigation:hide:finished'
		},
		clickable: {
			show: 'navigation:clickable:show',
			hide: 'navigation:clickable:hide'
		}
	},
	header: {
		in: 'header:in',
		out: 'header:out',
		do: {
			show: {
				cta: 'header:do:show:cta'
			},
			hide: {
				cta: 'header:do:hide:cta'
			}
		}
	},
	inview: {
		in: 'inview:in',
		change: 'inview:change',
		out: 'inview:out'
	},
	pagination: {
		clicked: 'pagination:clicked'
	}
}

export default Events;
