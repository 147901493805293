/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 02.06.2020.
 */

"use strict";

//import ScrollToPlugin from "gsap/ScrollToPlugin";
import { gsap } from "gsap/all";
//gsap.registerPlugin(ScrollToPlugin);
import delegate from "es6-delegate/delegate";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import Events from "../fieldcode.events";
import * as Utils from "../utilities/Utilities";
import Pristine from 'pristinejs/src/pristine';

/**
 * Class ACN (AccessNow)
 */
class Acn extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		//this._baseurl = 'http://go.fieldcode.wtf/api/private/quick-setup-wizard-gsl';
		this._baseurl = '';

		this._validators = [];
		this._views = [];
		this._currentStep = 1;
		this._isAnimating = false;
		this._isCreating = false;

		this._form = this._element.querySelector('.form--acn');
		this._formInputWorkspace = this._form.querySelector('[name="tenant"]');
		this._formInputAddress = this._form.querySelector('[name="address"]');
		this._formInputZip = this._form.querySelector('[name="zip"]');
		this._formInputCity = this._form.querySelector('[name="city"]');
		this._formSelectCountry = this._form.querySelector('[name="country"]');
		this._formSelectState = this._form.querySelector('[name="us_states"]');
		this._formPassword = this._form.querySelector('.password' );

		this._leftContainer = this._element.querySelector('.acn__left');
		this._rightContainer = this._element.querySelector('.acn__right');
		this._background = this._element.querySelector('.acn__background');
		this._introTl = null;

		this._countrySelect = this._element.querySelector('select[name="country"]');
		this._usStateSelectGrp = this._element.querySelector('.us-state-select');
		this._usStateSelect = this._usStateSelectGrp.querySelector('SELECT');
		this._redirectLink = this._element.querySelector('.acn__redirect-link');

		this._init();
	}

	//public

	getInstance() {
		return this;
	}

	//get/set

	//private

	_init() {
		this._element.dataset.init = true;

		this._views[1] = this._element.querySelector('.acn__view[data-step="1"]');
		this._views[2] = this._element.querySelector('.acn__view[data-step="2"]');
		this._views[3] = this._element.querySelector('.acn__view[data-step="3"]');
		this._views[4] = this._element.querySelector('.acn__view[data-step="4"]'); //progress
		this._views[5] = this._element.querySelector('.acn__view[data-step="5"]'); //success
		this._views[6] = this._element.querySelector('.acn__view[data-step="6"]'); //error


		gsap.set(this._rightContainer, {opacity: 0});
		gsap.set(this._background, {  width: 0 });

		this._introTl = gsap.timeline({
			paused: true,
			onStart: () => {
			},
			onComplete: () => {
			},
			onReverseComplete: () => {

			}
		});

		history.replaceState({}, document.title, acn);


		this._initValidators();
		this._onCountrySelect(null,null);
		this._attachEventsAndListeners();
	}

	/**
	 * Init all form validators and add custom error messages
	 * @private
	 */
	_initValidators() {
		let validatorOptions = {
			classTo: 'form__group',
			errorClass: 'form__group--has-error',
			successClass: 'form__group--has-success',
			//errotTextParent: false
			errorTextParent: 'form__group',
			errorTextTag: 'div',
			errorTextClass: 'tooltip tooltip--half'
		}
		//complete form
		this._validators[0] = new Pristine(this._form, validatorOptions, false);
		//this._validators[0].addValidator(this._formInputWorkspace, this._checkTenantField.bind(this), "Tentant already taken or not allowed chars (use 'test')", 99, true);

		//step 1 - 3
		this._validators[1] = new Pristine(this._views[1], validatorOptions,  false);
		//this._validators[1].addValidator(this._formInputWorkspace, this._checkTenantField.bind(this), "Tentant already taken or not allowed chars (use 'test')", 99, true);
		this._validators[2] = new Pristine(this._views[2], validatorOptions, false);
		this._validators[3] = new Pristine(this._views[3], validatorOptions, false);

		Pristine.addMessages(window.locale, window.jsErrorMessages);
		Pristine.setLocale(window.locale);


	}

	/**
	 * Attach all eventlisteners or dispatchers
	 * @private
	 */
	_attachEventsAndListeners() {
		window.addEventListener(Events.preload.finished, this._onPreloadFinished.bind(this));
		window.addEventListener(Events.layout.resize.did, this._onResizeDid.bind(this));

		delegate(this._element, 'click', '[data-goto-step]', this._onClickGotoStep.bind(this));
		delegate(this._element, 'change', 'select[name="country"]', this._onCountrySelect.bind(this));

		this._form.addEventListener('submit', this._onSubmit.bind(this));
	}

	/**
	 * Check tenant name, first on client side then on server side
	 * @param value
	 * @returns {Promise}
	 * @private
	 */
	_checkTenantField(value) {
		return new Promise((resolve, reject) => {
			/*
			^                     # Anchor to start of string.
                        (?!.{256})            # Whole domain must be 255 or less.
                        (?:                   # One or more sub-domains.
                          [a-z]               # Subdomain begins with alpha-num.
                          (?:                 # Optionally more than one char.
                            [a-z0-9-]{0,61}   # Middle part may have dashes.
                            [a-z0-9]          # Starts and ends with alpha-num.
                          )?                  # Subdomain length from 1 to 63.
                          \.                  # Required dot separates subdomains.
                        )+                    # End one or more sub-domains.
                        (?:                   # Top level domain (length from 1 to 63).
                          [a-z]{1,63}         # Either traditional-tld-label = 1*63(ALPHA).
                        | xn--[a-z0-9]{1,59}  # Or an idn-label = Restricted-A-Label.
                        )                     # End top level domain.
                        $                     # Anchor to end of string.
			*/

			/*
			 (?!.*--)             # Assert that there are no -- present anywhere
			 */

			//if (/^(?!.{256})(?:[a-z](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+(?:[a-z]{1,63}|xn--[a-z0-9]{1,59})$/i.test(value + '.fieldcode.com')) {
			if (/^(?!.*--)[a-z](?:[a-z0-9-]{0,61}[a-z0-9])$/i.test(value)  && value.length <= 16) {
				this._communicate('accessnow/com/ctn/'+value)
					.then(respone => {
						resolve(respone);
						//return true;
					})
					.catch(error => {
						this._validators[1].addError(this._formInputWorkspace, ACN_MESSAGES.workspaceError);
						reject(error);
						//return false;
					})
			}
			else {
				this._validators[1].addError(this._formInputWorkspace, ACN_MESSAGES.workspaceNamingError);
				reject("naming convention error");
			}
			//return false;

		})
	}

	/**
	 * Validates User address on server
	 * @param data
	 * @returns {Promise}
	 * @private
	 */
	_validateAddress(data) {
		return new Promise((resolve, reject) => {
			this._communicate('accessnow/com/va', data, 'POST')
				.then(response => {
					if (!response.error) {
						resolve(response.message);
					}
					else {
						throw new Error(response.message);
					}
				})
				.catch(error => {
					console.log(this._validators);
					console.log(this._formInputAddress);

					this._validators[3].addError(this._formInputAddress, ACN_MESSAGES.addressNotValid);

					reject(error);
				})
		})
	}

	/**
	 * Finally send data to fieldcode and create account with the colleted data
	 * @param data
	 * @returns {Promise}
	 * @private
	 */
	_createAccount(data) {
		//console.log("create account with", data.tenant);
		//return new Promise((resolve, reject) => {
		//	reject('test mode - no account created');
		//})


		return new Promise((resolve, reject) => {
			let resolveInstant = false;
			if (resolveInstant) {
				resolve('https://'+data.tenant+'.fieldcode.' + (devmode ? 'rocks' : 'com'));
			}
			else {
				this._communicate('accessnow/com/ca', data, 'POST')
					.then(response => {
						if (!response.error) {

							console.log("account created with", response);
							if (data.tenant) {
								resolve('https://' + data.tenant + '.fieldcode.' + (devmode ? 'rocks' : 'com'));
							} else {
								resolve(response.data);
							}

							//url is not returned from service use user entry
							//resolve(response.data); //data == redirect link
						} else {
							throw new Error(response.message);
						}
					})
					.catch(error => {
						//this._validators[3].addError(this._formInputAddress, ACN_MESSAGES.addressNotValid);
						reject(error);
					})
			}
		})
	}

	/**
	 * Begin the account creation process
	 * @returns {boolean}
	 * @private
	 */
	_startAccountCreation() {
		if (this._isCreating) return false;
		this._isCreating = true;

		this._element.querySelectorAll('.acn__step').forEach((item, idx) => {
			item.classList.remove('acn__step--active');
		});


		//linkedin tracking
		let linkedInPixel = new Image(1,1);
		linkedInPixel.src = 'https://px.ads.linkedin.com/collect/?pid=2113457&conversionId=2546441&fmt=gif';
		linkedInPixel.style.display = 'none';
		this._leftContainer.appendChild(linkedInPixel);


		if (Utils.Helper.isFunction(window.capterraTrackingListner)) {
			window.capterraTrackingListner();
		}
		else {
			console.log("Capterra Tracking not active");
		}

		let accountData = new FormData(this._form);
		accountData.append('locale', locale);

		this._createAccount(Object.fromEntries(accountData))
			.then(redirectLink => {
				this._onAccountCreationSuccess(redirectLink);
			})
			.catch(error => {
				this._onAccountCreationFailure(error)
			})



		//setTimeout(() => {
		//	this._onAccountCreationSuccess()
		//}, 10000);
	}

	/**
	 * If account creation successfully, redirect user to fieldcode app (only on desktop)
	 * @param redirectLink
	 * @private
	 */
	_onAccountCreationSuccess(redirectLink) {
		this._isCreating = false;
		//this._switchView(6);

		console.log("redirect to:", redirectLink);

		if (!redirectLink) redirectLink = 'http://go.fieldcode.works';
		if (this._redirectLink) {
			this._redirectLink.setAttribute('href', redirectLink);
		}
		this._switchView(5);



		/* diabled redirect on desktop
		if (window.device == 'desktop' || window.device == 'tablet') {

			let timeout = 3000;
			setTimeout(() => {
				document.location.href = redirectLink;
			}, timeout);
		}*/

		/* with mobile redirect
		let timeout = 5000; //mobile
		if (window.device == 'desktop') {
			timeout = 3000;
		}

		setTimeout(() => {
			document.location.href = redirectLink;
		}, timeout);*/
	}

	/**
	 * Called if some error occurs on creation proccess,
	 * The user still gets a success message (soft failure).
	 * The fieldcode team creates the account manually if necessary.
	 * @param error
	 * @private
	 */
	_onAccountCreationFailure(error) {
		this._isCreating = false;

		console.error(error);
		this._switchView(6);
	}

	/**
	 * On submit form (last step), validates all data again and trigger creation process
	 * @param evt
	 * @returns {boolean}
	 * @private
	 */
	_onSubmit(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		if (this._currentStep == 3) {
			if (this._validators[3].validate()) {
				let addressData = {
					address: this._formInputAddress.value,
					zip: this._formInputZip.value,
					city: this._formInputCity.value,
					country: this._formSelectCountry.value,
					state: this._formSelectState.value
				}

				this._validateAddress(addressData)
					.then(response => {
						//check complete form again
						if (this._validators[0].validate()) {
							this._switchView(4);
							setTimeout(() => {
								this._startAccountCreation();
							},500) //time for switchView Timeline, needed for mobile

						}
						else {
							throw new Error(this._validators[0].getErrors());
						}
					})
					.catch(error => {
						console.error(error);
					})
			}
			else {
				console.log("error", this._validators[3].getErrors());
			}
		}

		return false;
	}

	/**
	 * Handles the process troug the steps
	 * @param evt
	 * @param element
	 * @private
	 */
	_onClickGotoStep(evt, element) {
		if (evt) evt.preventDefault();
		let step = parseInt(element.dataset.gotoStep), pBtn = null, novalidation = false;

		if (element.dataset.gotoValidation == 'no' || step < this._currentStep) {
			novalidation = true;
		}

		if (element.type == 'button') {
			pBtn = element;
			if (!pBtn.classList.contains('button--progress')) {
				pBtn.classList.add('button--progress');
			}
		}

		this._stepValidation(this._currentStep, novalidation)
			.then((message) => {
				this._switchView(step);
			})
			.catch((error) => {
				//do nothin
				console.error("step validation error", error);
			})
			.finally(() => {
				if (pBtn) {
					pBtn.classList.remove('button--progress');
					pBtn = null;
				}
			})

	}

	/**
	 * Set / unset the state-field requirment based on country selection
	 * @param evt
	 * @param element
	 * @private
	 */
	_onCountrySelect(evt, element) {
		if (this._countrySelect.value == 'US' && this._usStateSelectGrp) {
			this._usStateSelectGrp.classList.remove('form__group--nvc');
			this._usStateSelect.setAttribute('required', true);
		}
		else {
			this._usStateSelectGrp.classList.add('form__group--nvc');
			this._usStateSelect.removeAttribute('required');
		}
	}

	/**
	 * Helper for GSAP
	 * @param evt
	 * @private
	 */
	_onResizeDid(evt) {
		gsap.to(this._background, {
			width: Utils.Helper.getViewportWidth() - (Utils.Helper.getOffset(this._leftContainer).left + Utils.Helper.getWidth(this._leftContainer, true)),
			duration: 0.2,
			ease: 'expo.out'
		});
	}

	/**
	 * If preloading done, show contents (GSAP)
	 * @param evt
	 * @private
	 */
	_onPreloadFinished(evt) {

		console.log("acn on preload finished", evt);

		this._introTl
			.fromTo(this._background, {
				width: 0,
				opacity: 1,
			}, {
				width: Utils.Helper.getViewportWidth() - (Utils.Helper.getOffset(this._leftContainer).left + Utils.Helper.getWidth(this._leftContainer, true)),
				duration: 0.8,
				ease: 'expo.out'

			})
			.to(this._rightContainer, {
				opacity: 1,
				duration: 0.6,
				ease: 'expo.out'
			}, '-=0.2');

		this._introTl.play();
	}

	/**
	 * Handles the step-validation, if novalidation set user can move to next step with form-erros (is used on step-back-buttons)
	 * @param step
	 * @param novalidation
	 * @returns {Promise}
	 * @private
	 */
	_stepValidation(step, novalidation) {
		console.log("step validation step:", step);
		return new Promise((resolve, reject) => {
			if (novalidation) {
				resolve('no validation mode');
				return;
			}

			if (this._validators[step] && this._validators[step].validate()) {
				if (step === 1) {
					this._checkTenantField(this._formInputWorkspace.value)
						.then((message) => {
							resolve(message);
						})
						.catch((error) => {
							reject(error);
						});
				}
				else if (step == 2) {
					if (this._formPassword && this._formPassword.data.instance) {
						this._formPassword.data.instance.validate()
							.then((message) => {
								resolve(message);
							})
							.catch((invalidConditions) => {
								this._validators[2].addError(this._formPassword.data.instance.inputElement, ACN_MESSAGES.passwordNotValid);
								console.log("password error", invalidConditions, this._validators[2]);
								reject(invalidConditions);
					
							});
					}
					else {
						resolve();
					}
				}
				else {
					resolve();
				}
			}
			else {
				//step 4 (progress) ...
				reject();
			}
		})
	}

	/**
	 * Handles XHR communication
	 * @param url
	 * @param data
	 * @param method
	 * @returns {Promise}
	 * @private
	 */
	_communicate(url, data, method) {
		let body = null;
		if (data) {
			body = JSON.stringify(data)
		}
		return new Promise((resolve, reject) => {
			fetch(this._baseurl + '/' + locale + '/'+ url, {
				method: method ? method : 'GET',
				cache: 'no-cache',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json'
					// 'Content-Type': 'application/x-www-form-urlencoded',
				},
				redirect: 'follow',
				referrerPolicy: 'no-referrer',
				body: body
			})
				.then(response => response.json())
				.then(json => {
					console.log("communicate json:", json);

					if (json.error === true ) {
						throw new Error(json.message)
					}

					resolve(json);

				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	/**
	 * Only for dev, simulate fast view swichting
	 * @private
	 */
	/*
	_simulateFastSwitch() {
		this._switchView(3);
		setTimeout(() => {
			this._switchView(4);

			setTimeout(() => {
				this._switchView(5);
			}, 500);

		}, 2000);
	}*/

	/**
	 * Handles frontend views / steps
	 * @param to
	 * @private
	 */
	_switchView(to) {
		let     currentView = this._element.querySelector('.acn__view[data-step="'+this._currentStep+'"]'),
			nextView = this._element.querySelector('.acn__view[data-step="'+to+'"]'),
			currentStep = this._element.querySelector('.acn__step[data-goto-step="'+this._currentStep+'"]'),
			nextStep = this._element.querySelector('.acn__step[data-goto-step="'+to+'"]'),
			currentFromY,currentToY,nextFromY,nextToY, historyUrl;

		console.log("switch view from :", this._currentStep, " to: ", to);

		if (currentView && nextView && this._currentStep !== to && !this._isAnimating) {
			if (!devmode) {
				if (to == 1) {
					historyUrl = acn;
				}
				else {
					historyUrl = acn + '/step-' + to;
				}
				history.replaceState({}, document.title, historyUrl);
			}

			/**
			 * google event tracking
			 * https://support.google.com/analytics/answer/1116091?hl=en
			 */

			if (window.dataLayer) {
				if (to == 5) {
					window.dataLayer.push({'event': locale + 'step5'})
				}

				if (to == 6) {
					window.dataLayer.push({'event': locale + 'step6'})
				}
			}
			else {
				console.log("Google TM not avail");
			}

			let tl = gsap.timeline({
				paused: true,
				onStart: () => {
					this._isAnimating = false //true;
					this._currentStep = to;

					if (currentStep) {
						currentStep.classList.remove('acn__step--selected');
					}

					if (nextStep) {
						nextStep.classList.add('acn__step--selected');
						if (!nextStep.classList.contains('acn__step--active')) {
							nextStep.classList.add('acn__step--active');
						}
					}

				},
				onComplete: () => {
					this._isAnimating = false;
					currentView.classList.remove('acn__view--active');
					nextView.classList.add('acn__view--active');
				},
				onReverseComplete: () => {
					this._isAnimating = false;
				}
			});

			if (this._currentStep < to) {
				currentFromY = 0, currentToY = '-100%';
				nextFromY = '100%', nextToY = 0;
			}
			else {
				currentFromY = 0, currentToY = '100%';
				nextFromY = '-100%', nextToY = 0;
			}


			tl.fromTo(currentView, {
				opacity: 1,
				visibility: 'visible',
				y: currentFromY
			}, {
				opacity: 0,
				visibility: 'hidden',
				y: currentToY,

				duration: 0.5,
				ease: 'power2.out',
			}).fromTo(nextView, {
				opacity: 0,
				visibility: 'hidden',
				y: nextFromY,
			}, {
				opacity: 1,
				visibility: 'visible',
				y: nextToY,

				duration: 0.5,
				ease: 'power2.inOut',
			}, '-=0.5');

			tl.play();
		}
	}

}

export default Acn;