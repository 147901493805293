/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 01.10.2019.
 *
 * https://stackoverflow.com/questions/34655616/create-an-instance-of-a-class-in-es6-with-a-dynamic-name
 */
//class ComponentFactory {
//	constructor() {
//
//	}
//}
//
//export default ComponentFactory;

//import ScrollIndicator from "../components/ScrollIndicator";
import Header from "../components/Header";
import Section from "../components/Section";
import Hamburger from "../components/Hamburger";
import Form from "../components/Form";
import Appslider from "../components/Appslider";
import Animation from "../components/Animation";
import Features from "../components/Features";
import Accordion from "../components/Accordion/Accordion";
import Select from "../components/Select";
import Support from "../components/Support/Support";
import Sharebox from "../components/Sharebox";
import Flash from "../components/Flash";
import Background from "../components/Background";
import Range from "../components/Range";
import Acn from "../components/Acn";
import RequestDemo from '../components/RequestDemo';
import Overlay from '../components/Overlay';
import Sidetoolbar from '../components/Sidetoolbar';
import Datatable from '../components/Datatable';
import Password from '../components/Password';
import Video from '../components/Video';
import Calculator from '../components/Calculator/Calculator';
import InputPlaceholder from '../components/InputPlaceholder';
import PricingTable from '../components/PricingTable';
import Countup from '../components/Countup';
import Featurehighlights from '../components/Featurehighlights';
import Logoslider from '../components/Logoslider';
import Tabs from '../components/Tabs';
import Pricingcompare from '../components/Pricingcompare';
import Sectionslider from '../components/Sectionslider';
import SocialShare from '../components/SocialShare';

const classes = {
	Header,
	Section,
	Hamburger,
	Form,
	Appslider,
	Animation,
	Features,
	Accordion,
	Select,
	Support,
	Sharebox,
	Flash,
	Background,
	Range,
	Acn,
	RequestDemo,
	Overlay,
	Sidetoolbar,
	Datatable,
	Password,
	Video,
	Calculator,
	InputPlaceholder,
	PricingTable,
	Countup,
	Featurehighlights,
	Logoslider,
	Tabs,
	Pricingcompare,
	Sectionslider,
	SocialShare
}

export default function ComponentFactory(name) {
	//console.log("ComponentFactory", name, classes);
	return classes[name];
}