/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 24.06.2020.
 */


"use strict";

import { gsap } from "gsap/all";
import delegate from "es6-delegate/delegate";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import Events from "../fieldcode.events";


class Overlay extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._background = this.element.querySelector('.overlay__background');
		this._wrapper = this.element.querySelector('.overlay__wrapper');
		this._localStore = window.localStorage;

		this._tl = null;
		this._init();
	}

	//public

	//get/set

	//private

	_init() {
		this._element.dataset.init = true;

		if ((this._localStore && this._localStore.getItem('flc-nl-ovl')) || window.device == 'phone') {
			this._element.remove();
		}
		else {

			this._tl = gsap.timeline({
				paused: true,
				onStart: () => {
				},
				onComplete: () => {
				},
				onReverseComplete: () => {
					this._element.remove();
				}
			});

			this._tl
				.set(this._element, {
					display: 'block'
				})
				.to(this._background, {
					opacity: 1,
					ease: 'strong2.out',
					duration: 0.2
				})
				.fromTo(this._wrapper, {
					opacity: 0,
					y: '0%',
				}, {
					opacity: 1,
					y: '-50%',
					ease: 'strong2.out',
					duration: 0.4
				}, 0);

			this._attachEventsAndListeners();
		}
	}

	_attachEventsAndListeners() {
		window.addEventListener(Events.preload.finished, this._onPreloadFinished.bind(this));
		delegate('click', '[data-overlay-close]', this._onClickClose.bind(this));
	}

	_onPreloadFinished(evt) {
		setTimeout(() => {
			this._tl.timeScale(1.3);
			this._tl.play();
		}, 400);
	}

	_onClickClose(evt, element) {
		if (evt) evt.preventDefault();
		if (this._localStore) {
			this._localStore.setItem('flc-nl-ovl', 'true');
		}
		this._tl.timeScale(2);
		this._tl.reverse();
	}
}

export default Overlay;