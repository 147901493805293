/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 21.10.2020.
 */

"use strict";

class Form {
	/**
	 * FIlter input by regex / function
	 *
	 * https://jsfiddle.net/emkey08/zgvtjc51
	 *
	 * inputFileder(document.getElementById("myTextBox"), function(value) {
         *     return /^\d*\.?\d*$/.test(value); // Allow digits and '.' only, using a RegExp
	 * });
	 *
	 * @param element
	 * @param filter
	 */
	static inputFilter(element, filter) {
		['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(function(event) {
			element.addEventListener(event, function() {
				if (filter(this.value)) {
					this.oldValue = this.value;
					this.oldSelectionStart = this.selectionStart;
					this.oldSelectionEnd = this.selectionEnd;
				} else if (this.hasOwnProperty('oldValue')) {
					this.value = this.oldValue;
					this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
				} else {
					this.value = '';
				}
			});
		});
	}
}

export default Form;