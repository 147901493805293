/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 29.10.2019.
 */

"use strict";

import RangeSlider from 'rangeslider-pure/dist/range-slider';

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import delegate from 'es6-delegate/delegate';

class Range extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._rangeInput = null;
		this._rangeValue = null;

		this._rangeslider = null;

		this._init();

	}

	_init() {
		this._element.dataset.init = true;

		this._rangeInput = this._element.querySelector('.rangeslider__input');
		this._rangeValue = this._element.querySelector('.rangeslider__value');

		let rangeOptions = {}
		this._rangeslider = RangeSlider.create(this._rangeInput, rangeOptions);

		this._attachEventsAndListeners();

		this._update();
	}

	_attachEventsAndListeners() {
		delegate('input', this._rangeInput, this._onInput.bind(this));
	}

	_update() {
		let output = this._rangeInput.value.padStart(2, '0');
		this._rangeValue.innerHTML = output;
	}

	_onInput(evt, element) {
		this._update();
	}
}

export default Range;