/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 14.10.2019.
 */


"use strict";

import BaseClass from "../../classes/BaseClass";
import Init from "../../classes/Init";
import Events from "../../fieldcode.events";
import AccordionItem from "./AccordionItem";

class Accordion extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);

		this._items = [];
		this._itemElements = this._element.querySelectorAll('.accordion__item');

		this._init();

	}
	//public

	//private
	_init() {
		this._element.dataset.init = true;
		this._itemElements.forEach((item, idx) => {
			this._items.push(new AccordionItem(item));
		});

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		this._element.addEventListener(Events.general.instance.get, this._onInstanceGet.bind(this));
	}

	_onInstanceGet(evt) {
		console.log("on instance get", evt);
		if (evt.detail.element == this._element) {
			return this;
		}
	}
}

export default Accordion;