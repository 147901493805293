/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 23.09.2020.
 */
"use strict";

import { gsap } from "gsap/all";
import delegate from "es6-delegate/delegate";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import Events from "../fieldcode.events";
import * as Utils from "../utilities/Utilities";

class RequestDemo extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._leftContainer = this._element.querySelector('.acn__left');
		this._rightContainer = this._element.querySelector('.acn__right');
		this._background = this._element.querySelector('.acn__background');
		this._introTl = null;

		this._init();
	}

	//public

	//get/set

	//private

	_init() {
		this._element.dataset.init = true;

		gsap.set(this._rightContainer, {opacity: 0});
		gsap.set(this._background, {  width: 0 });

		this._introTl = gsap.timeline({
			paused: true,
			onStart: () => {
			},
			onComplete: () => {
			},
			onReverseComplete: () => {

			}
		});

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		window.addEventListener(Events.preload.finished, this._onPreloadFinished.bind(this));
		window.addEventListener(Events.layout.resize.did, this._onResizeDid.bind(this));
	}

	_onResizeDid(evt) {
		gsap.to(this._background, {
			width: Utils.Helper.getViewportWidth() - (Utils.Helper.getOffset(this._leftContainer).left + Utils.Helper.getWidth(this._leftContainer, true)),
			duration: 0.2,
			ease: 'expo.out'
		});
	}

	_onPreloadFinished(evt) {
		this._introTl
			.fromTo(this._background, {
				width: 0,
				opacity: 0.4,
			}, {
				width: Utils.Helper.getViewportWidth() - (Utils.Helper.getOffset(this._leftContainer).left + Utils.Helper.getWidth(this._leftContainer, true)),
				duration: 0.8,
				ease: 'expo.out'

			})
			.to(this._rightContainer, {
				opacity: 1,
				duration: 0.6,
				ease: 'expo.out'
			}, '-=0.2');

		this._introTl.play();
	}
}


export default RequestDemo;