/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 22.10.2020.
 */
"use strict";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import * as Utils from "../utilities/Utilities";

class InputPlaceholder extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);
		this._placeholder = null;
		this._observer = null;
		this._init();

	}

	_init() {
		this._element.dataset.init = true;
		this._observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.type == 'attributes' && mutation.attributeName == 'placeholder') {
					this._update();
				}
			})
		})

		this._create();
		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		this._observer.observe(this._element, {
			attributes: true
		})

	}

	_create() {
		this._placeholder = document.createElement('div');
		this._placeholder.classList.add('form__input-placeholder');
		Utils.Dom.insertAfter(this._placeholder, this._element);
		this._update();
	}

	_update() {
		this._placeholder.innerText = this._element.getAttribute('placeholder');
	}
}

export default InputPlaceholder;