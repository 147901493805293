/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 10.06.2021.
 */

"use strict";

import { gsap } from "gsap/all";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import * as Utils from "../utilities/Utilities";
import delegate from 'es6-delegate/delegate';
import Events from '../fieldcode.events';

class Logoslider extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._animationInProgress = false;
		this._items = this._element.querySelectorAll('.logoslider__item');
		this._small = false;
		this._rowCount = null;
		this._timeline = null;

		this._init();
	}

	_init() {
		this._element.dataset.init = true;
		this._app.handlers.intersection.attach(this._element);

		if (this._element.classList.contains('logoslider--small')) {
			this._small = true;
		}

		if (this._small == false && this._items.length < 5) return;
		this._rowCount = this._small ? this._smallHandler() : this._defaultHandler();
		this._buildTimeline();
		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		window.addEventListener(Events.layout.resize.did, this._refreshAnimation.bind(this));
		this._element.addEventListener(Events.inview.in, this._onInViewIn.bind(this));
	}

	_buildTimeline() {
		this._timeline = gsap.timeline({
			repeat: -1,
			repeatRefresh: true,
			paused: true,
			onStart: () => {
				this._animationInProgress = true;
			}
		});

		this._timeline
			.to(this._items, {
				y: '-=100%',
				duration: 0.8,
				delay: 3,
				repeat: (this._rowCount - 1),
				repeatDelay: 3,
				repeatRefresh: true
			})
			.to(this._items, {
				opacity: 0,
				duration: 0.2,
				delay: 5
			})
			.set(this._items, {
				y: 0
			})
			.to(this._items, {
				opacity: 1,
				duration: 0.2,
				delay: 0
			})
	}

	_refreshAnimation(evt) {
		this._timeline.kill();
		this._timeline = null;

		gsap.to(this._items, { clearProps: true });

		this._animationInProgress = false;
		this._rowCount = this._small ? this._smallHandler() : this._defaultHandler();

		console.log(`refresh animation with row count ${this._rowCount}`);
		console.log(this._timeline);

		this._buildTimeline();
		this._timeline.play();

	}

	_onInViewIn(evt) {
		if (this._animationInProgress) return;
		this._timeline.play();
	}

	_smallHandler() {
		let wrapperWidth = Utils.Helper.getWidth(this._element.querySelector('.logoslider__wrapper'), true);
		let itemWidth = Utils.Helper.getWidth(this._items[0], true);

		console.log(`${Math.floor(this._items.length - 1)} / ${Math.floor(wrapperWidth / itemWidth)}`);

		return Math.floor((this._items.length - 1) / Math.floor(wrapperWidth / itemWidth));
	}

	_defaultHandler() {
		let rowCount = Math.floor((this._items.length - 1) / 4);
		if (Utils.Helper.getViewportWidth() < 1024) {
			rowCount = Math.floor((this._items.length - 1) / 3);
		}
		else if (Utils.Helper.getViewportWidth() < 768) {
			rowCount = Math.floor((this._items.length - 1) / 1);
		}

		return rowCount;
	}
}

export default Logoslider;