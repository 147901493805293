/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 29.02.2020.
 */

"use strict";

import {gsap, MorphSVGPlugin} from "gsap/all";
gsap.registerPlugin(MorphSVGPlugin);

import Events from "../fieldcode.events";
import * as Utils from "../utilities/Utilities";
import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import delegate from "es6-delegate/delegate";

class Hamburger extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);
		this._timeline = null;
		this._animating = false;
		this._open = false;
		this._init();
	}

	/** public **/

	/** get / set **

	/** private **/

	_init() {
		this._element.dataset.init = true;

		//test
		this._timeline = gsap.timeline({
			onStart: () => {
				this._animating = true;
			},
			onComplete: () => {
				this._open = true;
				this._animating = false;
			},
			onReverseComplete: () => {
				this._open = false;
				this._animating = false;

			},
			paused: true
		});

		let mainSvg = this._element.querySelector('svg');
		let lineTop = this._element.querySelector('#line-top');
		let lineCenter = this._element.querySelector('#line-center');
		let lineBottom = this._element.querySelector('#line-bottom');
		let ball = this._element.querySelector('#ball');
		let metaball = this._element.querySelector('#metaball');


		this._timeline
			.to(lineTop, { duration: 0.1, opacity: 0, yPercent: '100' })
			.to(lineBottom, { duration: 0.1, opacity: 0, yPercent: '-100' }, 0)
			.to(lineCenter, { duration: 0.2, morphSVG: {shape: ball,  type: "rotational"}})
			.to(lineCenter, { duration: 0.1, morphSVG: metaball})
			.to(mainSvg, {duration: 0.1, rotate: 45}, '-=0.1');


		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		delegate('click', '.hamburger', this._toggle.bind(this));
		delegate('click', '[data-menu-toggle]', this._toggle.bind(this));


		window.addEventListener(Events.header.do.show.cta, this._cross.bind(this));
		window.addEventListener(Events.header.do.hide.cta, this._uncross.bind(this));
	}

	_cross(evt, elemnt) {
		if (this._animating) return;
		this._timeline.pause();
		this._timeline.play();
	}

	_uncross(evt, element) {
		if (this._animating) return;
		this._timeline.pause();
		this._timeline.reverse();
	}

	_toggle(evt, targetElement) {
		evt.preventDefault();
		let showEvt = Events.navigation.do.show, hideEvt = Events.navigation.do.hide;
		if (this._app.lifecycle.animating.header) return;
		if (this._animating) return;


		if (this._app.lifecycle.visible.headerCta) {
			showEvt = Events.header.do.show.cta;
			hideEvt = Events.header.do.hide.cta;
		}

		if (!this._open) {
			Utils.DispatchEvent.dispatch(showEvt, {
				action: () =>this._timeline.play()
			});

			//this._timeline.play();
			//this._open = true;
		}
		else {
			Utils.DispatchEvent.dispatch(hideEvt, {
				action: () =>this._timeline.reverse()
			});

			//this._timeline.reverse();
			//this._open = false;
		}
	}
}

export default Hamburger;