/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 21.10.2019.
 */


"use strict";

import delegate from "es6-delegate/delegate";

import Events from "../fieldcode.events";
import * as Utils from "../utilities/Utilities";
import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";

class Video extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);

		this._type = this._element.dataset.videoType;
		this._video = this._element.querySelector('video');
		this._controlsElement = this._element.querySelector('.video__controls');
		this._playing = false;
		if (this._type != 'youtube') {
			this._init();
		}

	}

	//public

	//private
	_init() {
		this._element.dataset.init = true;
		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {

		if (this._controlsElement) {
			this._controlsElement.addEventListener('click', this._onClickPlayPause.bind(this));
			delegate('click', '.video__control--play-pause', this._onClickPlayPause.bind(this));
		}

		//window.addEventListener(Events.layout.resize.did, this._onResize.bind(this));
		//window.addEventListener(Events.layout.mutation.oberserved, this._onResize.bind(this));
		//window.addEventListener(Events.preload.finished, this._onResize.bind(this));
	}

	_onClickPlayPause(evt, targetElement) {
		evt.preventDefault();
		evt.stopPropagation();

		let playPromise;

		if (targetElement == undefined) return;
		if (this._video !== targetElement.parentNode.parentNode.querySelector('video')) return;

		if (!this._playing) {
			this._element.classList.add('video--playing');
			this._video.controls = true;
			playPromise = this._video.play();

			// In browsers that don’t yet support this functionality,
			// playPromise won’t be defined.
			if (playPromise !== undefined) {
				playPromise.then(() => {
					this._playing = true;
					// Automatic playback started!
				}).catch((error) => {
					//console.log(error);
					this._playing = false;
					// Automatic playback failed.
					// Show a UI element to let the user manually start playback.
				});
			}
			else {
				this._playing = true;
			}
		}
		else {
			this._element.classList.remove('video--playing');
			this._video.controls = false;
			this._video.pause();
			this._playing = false;
		}


	}

}

export default Video;