/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 06.04.2020.
 */


"use strict";

import imagesLoaded from "imagesloaded/imagesloaded";
import { gsap } from "gsap/all";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import Events from '../fieldcode.events';

class Background extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);
		this._gfx = null;
		this._realimage = false;
		this._init();
	}

	//public

	//get/set

	//private

	_init() {
		this._element.dataset.init = true;
		this._path = this.element.dataset.path;
		this._realimage = this.element.dataset.mode == 'image' ? true : false;
		this._gfx = document.createElement('span');

		console.log("init background", this._element, this._realimage);

		if (!this._realimage) {
			fetch(this._path)
				.then(response => response.text())
				.then(data => {
					this._gfx.innerHTML = data;
					gsap.set(this._gfx, {opacity: 0});
					return this._gfx;
				})
				.then(htmlElement => {
					this._element.appendChild(htmlElement);
					gsap.to(htmlElement, {
						duration: 1.5,
						opacity: 1,
						ease: 'strong2.inOut'
					})
				});
		}
		else {
			let tempImageTag = document.createElement('img');
			tempImageTag.setAttribute('src', this._path);
			tempImageTag.setAttribute('width', parseInt(this._element.dataset.width));
			tempImageTag.setAttribute('height', parseInt(this._element.dataset.height));
			tempImageTag.setAttribute('alt', 'background')
			tempImageTag.setAttribute('aria-hidden', 'true');

			this._gfx.appendChild(tempImageTag);
			gsap.set(this._gfx, {opacity: 0});

		}

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {

		window.addEventListener(Events.preload.finished, this._onPreloadFinished.bind(this));
	}

	_onPreloadFinished(evt) {
		if (this._realimage) {
			console.log("page backround show image after preload finished");
			this._element.appendChild(this._gfx);
			imagesLoaded(this._gfx, {}, ( instance ) => {
				gsap.to(this._gfx, {
					duration: 1.5,
					opacity: 1,
					ease: 'strong2.inOut'
				})
			});
		}
	}
}

export default Background;