/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 08.11.2021.
 */

"use strict";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import Events from "../fieldcode.events";
import imagesLoaded from "imagesloaded/imagesloaded";
import * as Utils from "../utilities/Utilities";
import DKPagination from "../classes/Pagination";
import { gsap } from "gsap/all";

class Sectionslider extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._sliderWrapper = this._element.querySelector('.grid__container');
		//this._paginationElement = this._element.querySelector('.section-header__pagination');
		this._pagination = null;
		this._autoplay = 0;
		this._adaptiveHeight = false;

		this._flickity = null;



		//this._init();
	}

	//public

	//private

	_init() {
		let initialIndex = 0, pageDots = false;

		this._element.dataset.init = true;
		this._app.handlers.intersection.attach(this._element);


		//if (this._asSliderWrapper) {
		//	if (this._asSliderWrapper.dataset.autoplay) {
		//		this._autoplay = parseInt(this._asSliderWrapper.dataset.autoplay);
		//	}
		//	if (this._asSliderWrapper.dataset.adaptiveHeight) {
		//		this._adaptiveHeight = this._asSliderWrapper.dataset.adaptiveHeight === 'true' ? true : false;
		//	}
		//
		//}

		this._flickity = new Flickity(this._sliderWrapper, {
			//initialIndex: initialIndex,
			contain: false,
			pageDots: true,
			prevNextButtons: false,
			adaptiveHeight: this._adaptiveHeight,
			autoPlay: (!this._autoplay || this._autoplay <= 0) ? false : this._autoplay
			//on: { //bind listener, as the ready event may be triggered immediately
			//	ready: this._onFlickityReady.bind(this)
			//}
		});

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {

		if (this._flickity) {
			window.addEventListener(Events.preload.finished, this._onPreloadFinished.bind(this));
			window.addEventListener(Events.layout.mutation.oberserved, this._onLayoutMutationOberserved.bind(this));
			this._element.addEventListener(Events.inview.in, this._onInViewIn.bind(this));
		}

		//if (this._pagination) {
		//	this._element.addEventListener(Events.pagination.clicked, this._onPaginationItemClicked.bind(this));
		//	this._flickity.on( 'settle', this._onFlickitySettle.bind(this));
		//}
	}

	_goto(index) {
		this._flickity.select(index);

		//if (this._asTabs) {
		//	if (this._gridItems[index].classList.contains('grid--bg')) {
		//		if (this._headerElement) {
		//			let bgc = window.getComputedStyle(this._gridItems[index].querySelector('.grid__container')).backgroundColor;
		//			//gsap.to(this._headerElement, {
		//			//	duration: 0.28,
		//			//	backgroundColor: bgc
		//			//})
		//
		//			//this._headerElement.style.backgroundColor = bgc;
		//		}
		//	}
		//}
	}

	_onPaginationItemClicked(evt) {
		let idx = evt.detail.idx;

		//if (device == 'phone') {
		//	window.scrollTo({
		//		top: Utils.Helper.getOffset(this._element).top - 50,
		//		left: 0,
		//		behavior: 'smooth'
		//	});
		//}

		this._goto(idx);
	}

	_onFlickitySettle(index) {
		//if(this._pagination) {
		//	this._pagination.switchTo(index);
		//}
	}

	_onPreloadFinished(evt) {
		this._flickity.resize();
	}

	_onLayoutMutationOberserved(evt) {
	}

	_onInViewIn(evt) {
		imagesLoaded(this._element, {}, () => {
			console.log("image slider in view, images loaded resize flickty");
			this._flickity.resize();
		})
	}





}

export default Sectionslider;
