/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 27.03.2020.
 */

"use strict";

import gsap from "gsap/all";

import Events from "../../fieldcode.events";
import * as Utils from "../../utilities/Utilities";
import SupportItem from "./SupportItem";
import BaseClass from "../../classes/BaseClass";
import Init from "../../classes/Init";
import delegate from "es6-delegate/delegate";



class Support extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._header = this._element.querySelector('.support__header');
		this._navElements = this._header.querySelectorAll('.support__header-item');
		this._navElementActive = null;
		this._content = this._element.querySelector('.support__content');
		this._contentElements = this._content.querySelectorAll('.support__item');
		this._contentItems = [];
		this._contentItemActive = null;
		this._isOpen = false;
		this._isAnimating = false;

		this._init();
	}

	//public

	//private

	_init() {
		this._element.dataset.init = true;
		if (this._contentElements) {
			this._contentElements.forEach((item, idx) => {
				this._contentItems.push(new SupportItem(item));
			});
		}


		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		window.addEventListener(Events.layout.resize.did, this._onDidResize.bind(this));
		window.addEventListener(Events.preload.finished, this._onPreloadFinished.bind(this));
		this._element.addEventListener(Events.inview.in, this._onInViewIn.bind(this));
		delegate(this._element, 'click', '.support__header-item', this._onClickHeaderItem.bind(this));


	}

	_switchTo(index) {
		let switchToContentItem = null, switchToNavItem = null;
		if (this._isAnimating) return false;

		this._navElements.forEach((item ,idx) => {
			if (index === item.dataset.index) {
				switchToNavItem = item;
			}
		});

		this._contentItems.forEach((item ,idx) => {
			if (index === item.index) {
				switchToContentItem = item;
			}
		});

		if (switchToNavItem && switchToContentItem) {
			if (this._navElementActive) {
				this._navElementActive.classList.remove('support__header-item--active');
			}
			switchToNavItem.classList.add('support__header-item--active');
			this._navElementActive = switchToNavItem;

			this._isAnimating = true;
			this._animateSwitchTo(index).then(() => {
				//this._ready = true;
				this._isAnimating = false;
			}).catch((e) => {
				console.error(e);
			});
		}
	}

	/**
	 *
	 * @param index
	 * @returns {Promise<void>}
	 * @private
	 */
	async _animateSwitchTo(index) {
		let switchToItem = null;

		this._contentItems.forEach((item ,idx) => {
			if (index === item.index) {
				switchToItem = item;
			}
		});

		if (!switchToItem) throw new Error('item not found');

		if (this._contentItemActive && this._contentItemActive.index == switchToItem.index) {
			return Promise.resolve(1);
		}

		try {
			if (this._contentItemActive) {
				this._contentItemActive.calculateHeight().then((height) => {
					gsap.set(this._content, {height: height});
				});
				await this._animateItem(this._contentItemActive.content, 'hide');
				await this._contentItemActive.setDisplay('none');
			}
			await this._expandContent(switchToItem);
			await switchToItem.setDisplay('block');
			await this._animateItem(switchToItem.content, 'show');
			this._contentItemActive = switchToItem;
		}
		catch(e) {
			throw new Error('item not found');
		}
	}

	_animateItem(item, mode) {
		return new Promise((resolve, reject) => {
			gsap.set(item, {
				display: 'block',
				opacity: mode == 'hide' ? 1 : 0
			});
			gsap.to(item, {
				opacity: mode == 'hide' ? 0 : 1,
				duration: 0.3,
				onComplete: () => {
					resolve()
				}
			})
		})
	}

	_expandContent(item) {
		return new Promise((resolve, reject) => {
			gsap.to(this._content, {
				height: item.calculatedHeight,
				duration: 0.6,
				ease: 'quad.inOut',
				onComplete: () => {
					gsap.set(this._content, { height: 'auto' })
					resolve()
				}
			});
		})
	}

	_onClickHeaderItem(evt, element) {
		if (evt) evt.preventDefault();
		this._switchTo(element.dataset.index)

	}

	_onDidResize() {

	}

	_onInViewIn(evt) {
	}

	_onPreloadFinished(evt) {
		if (window.location.hash) {
			let hash = window.location.hash.substring(1);
			this._switchTo(hash);
		}
	}
}


export default Support;
