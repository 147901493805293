//import {removeScrollableTarget} from "scroll-lock/dist/scroll-lock";

/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 15.01.2020.
 */


const DEBUG = devmode;
if(!DEBUG){
	if(!window.console) window.console = {};
	//const methods = ["log", "debug", "warn", "info", "error"];
	const methods = ["log", "warn", "info", "error"];
	for(let i=0;i<methods.length;i++){
		console[methods[i]] = function(){};
	}
}
let app = app || {};

//import delegate from "es6-delegate/delegate";
import scrollLock  from 'scroll-lock';
import LazyLoad from "vanilla-lazyload";
import autosize from 'autosize';
import Pristine from "pristinejs/src/pristine";

import Events from './fieldcode.events';
import Config from './fieldcode.config';
import * as Utils from './utilities/Utilities';
import * as Handlers from './handlers/Handlers';

import PreloadController from "./classes/preloader/PreloadController";
import Section from "./components/Section";
import delegate from 'es6-delegate/delegate';

class Main {
	constructor() {
		this.started = false;
		this.lifecycle = {
			theme: {
				dark: false
			},
			elements: {
				body: document.body,
				wrapper: document.body,
				header: document.querySelector('.header'),
				navigation: {
					primary: document.querySelector('.navigation--primary')
				}
			},
			has: {
			},
			animating: {
				header: false
			},
			visible: {
				header: true,
				navigation: false,
				accessnow: false
			}
		};
		this.handlers = {};
		this.components = {};
		
		this.keys = {
			enter: 13,
			space: 32,
			tab: 9,
			esc: 27,
			left: 37,
			right: 39,
			up: 38,
			down: 50
		};

		this.Events = Events;
		this.Config = Config;
		this.Utils = Utils;
		this.Handlers = Handlers;

		this._resizeFinishedTimeout = null;

		this._init();

	}

	start() {
		if (this.started) return;
		this.started = true;


		if (isEdge) this.lifecycle.elements.body.classList.add('browser--edge');
		if (isSafari) this.lifecycle.elements.body.classList.add('browser--safari');
		//set or get prefered theme mode (dark / light
		this.lifecycle.theme.dark = localStorage.getItem('theme-darkmode') ? localStorage.getItem('theme-darkmode') : (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
		this.lifecycle.elements.body.dataset.theme = (/true/i).test(this.lifecycle.theme.dark) ? 'dark' : 'light';
		localStorage.setItem('theme-darkmode', this.lifecycle.theme.dark);

		//Utils.DispatchEvent.dispatch(Events.preload.begin, {}, window, true, false);
		//Utils.DispatchEvent.dispatch(Events.preload.finished, {}, window, true, false);
	}

	_init() {
		this._attachEventsAndListeners();

		this.handlers.preloadController = new PreloadController();
		this.handlers.mutation = new Handlers.MutationHandler(this.lifecycle.elements.wrapper);
		this.handlers.intersection = new Handlers.IntersectionHandler();
		this.handlers.resize = new Handlers.ResizeHandler();
		this.handlers.component = new Handlers.ComponentHandler(this.lifecycle.elements.wrapper, this);
		//this.handlers.component.doAfterInitComponents(() => {
		//	console.log("do stuff after init components ...");
		//})
		//this.handlers.scrollcontroller = new ScrollMagic.Controller();

		scrollLock.addFillGapTarget(this.lifecycle.elements.header);

		this._initComponents();

		Utils.DispatchEvent.dispatch(Events.preload.begin, {}, window, true, false, true);
	}

	_initComponents() {
		this.handlers.component.initComponents();
		this.components.lazyload = new LazyLoad({
			threshold: 100,
			//load_delay: 2000,
			elements_selector: '.lazy',
			class_loading: 'lazy--loading',
			class_loaded: 'lazy--loaded',
			class_error: 'lazy--error',
			callback_enter: (e) => {
				//console.log("lazyload image enter:",e.parentNode, e);
				if(e.parentNode) {
					let parent = e.parentNode;
					if (parent.tagName == 'PICTURE' || parent.classList.contains('image__link')) { parent = parent.parentNode }
					if (!Utils.Helper.isFunction(parent.classList)) return;
					parent.classList.add('lazy');
				}
			},

			callback_loading: (e) => {
				//console.log("lazyload image loading:",e.parentNode, e);
				if(e.parentNode) {
					let parent = e.parentNode;
					if (parent.tagName == 'PICTURE' || parent.classList.contains('image__link')) { parent = parent.parentNode }
					parent.classList.add('lazy--loading');
				}

				//picturefill({ reevaluate: true });
			},
			callback_loaded: (e) => {
				//console.log("lazyload image load:", e.parentNode, e);
				if(e.parentNode) {
					let parent = e.parentNode;
					if (parent.tagName == 'PICTURE' || parent.classList.contains('image__link')) { parent = parent.parentNode }
					parent.classList.add('lazy--loaded');
					parent.classList.remove('lazy--loading');

				}
				//picturefill({ reevaluate: true });
			},
			callback_error: (e) => {
				console.error("lazyload image error:",e.parentNode, e);
				if(e.parentNode) {
					let parent = e.parentNode;
					if (parent.tagName == 'PICUTRE' || parent.classList.contains('image__link')) { parent = parent.parentNode }
					parent.classList.add('lazy--error');
				}
			}
		});
		autosize(document.querySelectorAll('textarea:not(.no-as)'));

		//set global js error validation messages ... pristine js
		Pristine.addMessages(window.locale, window.jsErrorMessages);
		Pristine.setLocale(window.locale);
	}

	_attachEventsAndListeners() {
		Utils.Throttle.addEvent('resize', 'throttledResize');
		Utils.Throttle.addEvent('scroll', 'throttledScroll');
		
		window.addEventListener('orientationchange', this._onOrientationChange.bind(this));
		window.addEventListener('throttledResize', this._onOptimiziedResize.bind(this));
		window.addEventListener('throttledScroll', this._onOptimiziedScroll.bind(this));

		window.addEventListener(Events.preload.begin, this._onPreloadBegin.bind(this));
		window.addEventListener(Events.preload.finished, this._onPreloadFinished.bind(this));
		window.addEventListener(Events.layout.mutation.oberserved, this._onLayoutMutationOberserved.bind(this));

		window.addEventListener(Events.navigation.do.show, this._disableScroll.bind(this));
		window.addEventListener(Events.navigation.do.hide, this._enableScroll.bind(this));
		window.addEventListener(Events.navigation.clickable.show, this._disableScroll.bind(this));
		window.addEventListener(Events.navigation.clickable.hide, this._enableScroll.bind(this));
		window.addEventListener(Events.header.do.show.cta, this._disableScroll.bind(this));
		window.addEventListener(Events.header.do.hide.cta, this._enableScroll.bind(this));

		delegate('click', '.action-accessnow', this._onClickAcnButton.bind(this));
		delegate('click', '[data-accessnow]', this._onClickAcnButton.bind(this));
	}

	_disableScroll(evt) {

		//scrollLock.addScrollableTarget(this.lifecycle.elements.header.querySelector('.menu__content'));
		//scrollLock.addScrollableTarget(this.lifecycle.elements.header.querySelector('.access-now'));
		scrollLock.disablePageScroll(this.lifecycle.elements.header);
	}

	_enableScroll(evt) {
		//scrollLock.removeScrollableTarget(this.lifecycle.elements.header.querySelector('.menu__content'));
		//scrollLock.removeScrollableTarget(this.lifecycle.elements.header.querySelector('.access-now'));
		scrollLock.enablePageScroll(this.lifecycle.elements.header);
	}

	_onPreloadBegin(evt) {
		console.log("main: onPreloadBegin", window.location.hash);

		window.scrollTo(0, 0);
		if (window.location.hash) {
			const anchorElement = document.querySelector(window.location.hash);
			if (anchorElement) {

			}
		}
		else {
			window.scrollTo(0, 1);

		}
	}

	_onPreloadFinished(evt) {
		console.log("main: onPreloadFinished", window.location.hash);

		//let l = bodymovin.loadAnimation({
		//	container: document.querySelector('#test'), // the dom element that will contain the animation
		//	renderer: 'svg',
		//	loop: true,
		//	autoplay: true,
		//	path: '/static/fx/app-demo.json',
		//	name: 'test'
		//});
	        //
		//window.test = l;
		this.lifecycle.elements.body.classList.add('layout--preloading-done');
	}

	_onLayoutMutationOberserved(evt) {

	}

	_onOrientationChange(evt) {
		this._calculateDimensions(evt, false);
	}

	_onOptimiziedResize(evt) {
		this._calculateDimensions();
		clearTimeout(this._resizeFinishedTimeout);
		this._resizeFinishedTimeout = setTimeout(() => {
			Utils.DispatchEvent.dispatch(Events.layout.resize.did);
		}, 250);
	}

	_onOptimiziedScroll(evt) {
		let oe = evt.detail.originEvent, st = Utils.Helper.getScrollPosition('y');

		if (st > this.lifecycle.lastScrollTop) {
			this.lifecycle.scrollDirection = 'down';
		}
		else {
			this.lifecycle.scrollDirection = 'up';
		}

		Utils.DispatchEvent.dispatch(Events.layout.scroll.did, {
			scrollEvent: evt.detail.originEvent,
			scrollTop: st,
			scrollDirection: this.lifecycle.scrollDirection
		});

		this.lifecycle.lastScrollTop = st;

	}

	_calculateDimensions(evt, initialCall) {
		this.lifecycle.viewportHeight = Utils.Helper.getViewportHeight();
		this.lifecycle.viewportWidth = Utils.Helper.getViewportWidth();
		this.lifecycle.viewportRatio = app.lifecycle.viewportWidth / app.lifecycle.viewportHeight;

		Utils.DispatchEvent.dispatch(Events.layout.dimension.check, {
			initial: initialCall ? true : false
		});
	}


	_onClickAcnButton(evt, target) {
		let redirectTo = false;
		if (evt) evt.preventDefault();
		console.log("onClickACnButton", evt, target);

		if (target.dataset.accessnow && target.dataset.accessnow == 'requestdemo') {
			if (window.rqd) {
				redirectTo = window.rqd;
			}
		}
		else if (target.dataset.accessnow && target.dataset.accessnow == 'register') {
			if (window.acn) {
				redirectTo = window.acn;
			}
		}
		else {
			if (window.acn) {
				redirectTo = window.acn;
			}
		}

		if (redirectTo) {
			document.location.href = redirectTo;
			return true;
		}
	}
	
}

(() => {
	app = new Main();
	window.app = app; //new Main();

	if(document.addEventListener) {
		document.addEventListener('DOMContentLoaded', app.start.bind(app), false);
	}

	if (window.addEventListener) {
		window.addEventListener('load', app.start.bind(app));
	}
	else {
		if (window.onload) {
			var current = window.onload;

			window.onload = function (evt) {
				current(evt);
				app.start();
			}
		}
		else {
			window.onload = onLoad;
		}
	}
})();