/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 22.03.2020.
 */

"use strict";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import DKPagination from "../classes/Pagination";
import Events from "../fieldcode.events";

class Appslider extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._pagiantionElement = this._element.querySelector('.appslider__pagination');
		this._pagination = null;
		this._views = this._element.querySelectorAll('.appslider__view');
		this._viewActive = this._element.querySelector('.appslider__view--active');


		this._init();
	}

	_init() {
		this._element.dataset.init = true;

		if (this._pagiantionElement) {
			this._pagination = new DKPagination(this._pagiantionElement, this._element, this._app);
		}

		this._views.forEach((item, i) => {
			this._app.handlers.intersection.attach(item);
		});

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		this._element.addEventListener(Events.pagination.clicked, this._onPaginationItemClicked.bind(this));
	}

	_goto(index) {
		this._viewActive.classList.remove('appslider__view--active');
		this._views.forEach((item, i) => {
			if (index == parseInt(item.dataset.idx)) {
				this._viewActive = item;
				this._viewActive.classList.add('appslider__view--active');
			}
		})
	}

	_onPaginationItemClicked(evt) {
		let idx = evt.detail.idx;
		this._goto(idx);
	}
}

export default Appslider;