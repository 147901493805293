/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 22.03.2020.
 */

"use strict";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import delegate from "es6-delegate/delegate";
import * as Utils from "../utilities/Utilities";
import Events from "../fieldcode.events";

class DKPagination extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);
		this._items = this._element.querySelectorAll('.pagination__item');
		this._itemActive = this._element.querySelector('.pagination__item--active');

		this._additionalActiveClass = null;

		this._init();
	}

	//public
	switchTo(index) {
		this._switchTo(index);
	}

	setAdditionalActiveClass(className) {
		this._additionalActiveClass = className;
	}

	//get / set

	//private

	_init() {
		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		delegate(this._element, 'click', '.pagination__item', this._onClick.bind(this));
	}

	/**
	 *
	 * @param index
	 * @private
	 */
	_switchTo(index) {

		this._items.forEach((element, idx) => {
			if (idx == index) {
				this._switchActiveItemTo(element);
			}
		})
	}

	/**
	 *
	 * @param element
	 * @private
	 */
	_switchActiveItemTo(element) {
		this._itemActive.classList.remove('pagination__item--active');
		if (this._additionalActiveClass) {
			this._itemActive.classList.remove(this._additionalActiveClass);
		}
		this._itemActive = element;
		this._itemActive.classList.add('pagination__item--active');
		if (this._additionalActiveClass) {
			this._itemActive.classList.add(this._additionalActiveClass);
		}
	}

	_onClick(evt, element) {
		if (evt) evt.preventDefault();
		if (element == this._itemActive) return;
		this._switchActiveItemTo(element);

		Utils.DispatchEvent.dispatch(Events.pagination.clicked, {
			idx: parseInt(this._itemActive.dataset.idx)
		}, this._referenceElement);

	}
}

export default DKPagination;