/**
 * project: ACT
 * Created by devkitchen on 04.08.2017.
 */
"use strict";

import DispatchEvent from "./DispatchEvent";

class Dom {
	/** only for "code style" **/
	static insertBefore(newNode, referenceNode) {

		DispatchEvent.dispatch(app.events.domInsertBegin, {
			node: newNode,
			referenceNode: referenceNode,
			parentNode: referenceNode.parentNode
		});

		referenceNode.parentNode.insertBefore(newNode, referenceNode);

		DispatchEvent.dispatch(app.events.domInsertFinished, {
			node: newNode,
			referenceNode: referenceNode,
			parentNode: referenceNode.parentNode
		});
	}

	static insertAfter(newNode, referenceNode) {
		referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
	}

	static insertIn(newNode, referenceNode) {
		referenceNode.appendChild(newNode);
	}
}

export default Dom;