/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 09.10.2019.
 */
"use strict";

import PreloadBase from "./preloader/PreloadBase";

class Init extends PreloadBase{
	/**
	 *
	 * @param element
	 * @param referenceElement
	 * @param app
	 */
	constructor(element, referenceElement, app, preloadedThisComponent) {

		super(element, referenceElement, app, preloadedThisComponent);

		this._element = element;
		this._referenceElement = referenceElement;
		this._app = app;
		this._preloaded = super._preloaded;

		if ( this._element ) this._element.dataset.init = true;
	}

	//public
	isPreloaded() {
		return this._preloaded;
	}

	//get/set
	get element() {
		return this._element;
	}

	get instance() {
		return this;
	}

	//private

}

export default Init;