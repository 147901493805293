/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 13.10.2020.
 */


"use strict";

import gsap from "gsap/all";
import delegate from 'es6-delegate/delegate';

class CalculatorView {
	constructor(element, active) {
		this._element = element;
		this._initialPosition = parseInt(gsap.getProperty(this._element, 'x', '%'));
		this._currentPosition = this._initialPosition;
		this._step = 0; //0 = initial position, positive = left, negative = right of inital position
		this._active = active ? active : false;
		this._substeps;
		this._init();
	}

	//getter/setter

	get element() {
		return this._element;
	}

	get position() {
		return this._position;
	}

	get active() {
		return this._active;
	}

	set position(position) {
		this._position = position;
	}

	set active(isActive) {
		this._active = isActive;
	}


	//public
	/**
	 *
	 * @param toX
	 * @param toZIndex
	 * @returns {Promise}
	 */
	async move(toX, toZIndex) {
		return this._move(toX, toZIndex);
	}

	/**
	 *
	 * @param steps
	 * @param inDirection
	 * @param withDelay
	 * @returns {Promise|Promise<unknown>}
	 */
	animate(steps, inDirection, withDelay) {
		return this._animate(steps, inDirection, withDelay);
	}

	/**
	 *
	 * @param index
	 * @param showOrHide
	 * @returns {Promise|Promise<unknown>}
	 */
	animateSubstep(index, showOrHide) {
		return this._animateSubstep(index, showOrHide);
	}


	//private

	_init() {
		this._element.dataset.init = true;
		this._substeps = this._element.querySelectorAll('.calculator__view-substep');
		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {

	}

	/**
	 *
	 * @param toX
	 * @param toZIndex
	 * @returns {Promise}
	 * @private
	 */
	async _move(toX, toZIndex) {
		return new Promise((resolve) => {
			gsap.set(this._element, {
				x: toX,
				zIndex: toZIndex,
				onComplete: () => {
					resolve(toX, toZIndex);
				}
			});
		})
	}


	/**
	 *
	 * @param steps
	 * @param inDirection
	 * @param withDelay
	 * @returns {Promise}
	 * @private
	 */
	_animate(steps, inDirection, withDelay) {
		let xValue, duration = 0.6, ease = 'expo.inOut';
		if (steps == 'initial') {
			xValue = this._initialPosition + '%';
			//duration = (Math.abs(this._step) + 1) * duration;
			duration = 0.8;
			console.log(this._element, "duration initial", duration);
		}
		else {
			xValue = (inDirection == 'left' ? '-=' : '+=') + (steps * 100) + '%';
		}

		return new Promise((resolve) => {
			gsap.to(this._element, {
				x: xValue,
				duration: duration,
				ease: ease,
				delay: withDelay ? withDelay : 0,
				onComplete: () => {
					this._currentPosition = parseInt(gsap.getProperty(this._element, 'x', '%'));

					if (inDirection == 'left') {
						this._step++;
					}
					else if (inDirection == 'right') {
						this._step--;
					}
					else {
						this._step = 0;
					}

					resolve(this._step);

				}
			})
		})
	}

	_animateSubstep(index, showOrHide) {
		let y = '-100%', opacity = 0, display = 'none';
		if (showOrHide == 'show') {
			y = 0;
			opacity = 1;
			display = 'block';
		}

		return new Promise((resolve, reject) => {
			if (this._substeps && this._substeps[index]) {
				gsap.to(this._substeps[index], {
					y: y,
					opacity: opacity,
					display: display,
					ease: 'expo.inOut',
					duration: 0.4,
					onComplete: () => {
						resolve();
					}
				});
			}
			else {
				reject();
			}
		})
	}

}

export default CalculatorView;
