/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 14.11.2019.
 */
"use strict";

class Url {
	static addOrUpdateParameter(name, value) {
		const href = window.location.href;
		let regex = new RegExp("[&\\?]" + name + "=");
		if(regex.test(href))
		{
			regex = new RegExp("([&\\?])" + name + "=\\d+");
			window.location.href = href.replace(regex, "$1" + name + "=" + value);
		}
		else
		{
			if(href.indexOf("?") > -1)
				window.location.href = href + "&" + name + "=" + value;
			else
				window.location.href = href + "?" + name + "=" + value;
		}
	}
}

export default Url;