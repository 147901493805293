/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 15.01.2020.
 */

/**
 * Component definitions
 * Properties:
 * - classname: Real JS classname (export)
 * - selector: html dom selector for "autloading"
 * - loadInPimcoreEditmode: some components should not be loaded in pimcore backend, you can exlude it from "autoloading"
 *
 */
const Config = {
	components: [
		{ classname: 'Header', selector: '.header', loadInPimcoreEditmode: false},
		{ classname: 'Section', selector: '.section', loadInPimcoreEditmode: false},

		//{ classname: 'Menu', selector: '.mobile-menu', loadInPimcoreEditmode: true },
		{ classname: 'Hamburger', selector: '.hamburger', loadInPimcoreEditmode: true },
		{ classname: 'Form', selector: '.form--std', loadInPimcoreEditmode: true },
		{ classname: 'Appslider', selector: '.appslider', loadInPimcoreEditmode: false },
		{ classname: 'Animation', selector: '.animation', loadInPimcoreEditmode: true },
		{ classname: 'Features', selector: '.features', loadInPimcoreEditmode: false },
		{ classname: 'Accordion', selector: '.accordion', loadInPimcoreEditmode: false},
		{ classname: 'Select', selector: '.dropdown', loadInPimcoreEditmode: true},
		{ classname: 'Range', selector: '.rangeslider', loadInPimcoreEditmode: true},
		{ classname: 'Support', selector: '.support', loadInPimcoreEditmode: true},
		//{ classname: 'Accessnow', selector: '.access-now', loadInPimcoreEditmode: false},
		{ classname: 'Acn', selector: '.acn[data-mode="access-now"]', loadInPimcoreEditmode: true},
		{ classname: 'RequestDemo', selector: '.acn[data-mode="request-demo"]', loadInPimcoreEditmode: true},
		{ classname: 'Sharebox', selector: '.sharebox', loadInPimcoreEditmode: false},
		{ classname: 'Flash', selector: '.flash', loadInPimcoreEditmode: false},
		{ classname: 'Overlay', selector: '.overlay', loadInPimcoreEditmode: false},
		{ classname: 'Background', selector: '[data-background]', loadInPimcoreEditmode: true},
		{ classname: 'Sidetoolbar', selector: '.side-toolbar', loadInPimcoreEditmode: false },
		{ classname: 'Datatable', selector: '.datatable', loadInPimcoreEditmode: true },
		{ classname: 'Password', selector: '.password', loadInPimcoreEditmode: true },
		{ classname: 'Video', selector: '.video', loadInPimcoreEditmode: false},
		{ classname: 'Calculator', selector: '.calculator', loadInPimcoreEditmode: false},
		{ classname: 'InputPlaceholder', selector: 'input[type="text"][data-input-placeholder]', loadInPimcoreEditmode: true},
		{ classname: 'PricingTable', selector: '.pricing-table', loadInPimcoreEditmode: true},
		{ classname: 'Countup', selector: '.countup', loadInPimcoreEditmode: false},
		{ classname: 'Featurehighlights', selector: '.featurehighlights', loadInPimcoreEditmode: false},
		{ classname: 'Logoslider', selector: '.logoslider', loadInPimcoreEditmode: true},
		{ classname: 'Tabs', selector: '.tabs', loadInPimcoreEditmode: false},
		{ classname: 'Pricingcompare', selector: '.pricingcompare', loadInPimcoreEditmode: false},
		{ classname: 'Sectionslider', selector: '.sectionslider', loadInPimcoreEditmode: false},
		{ classname: 'SocialShare', selector: '.social-share', loadInPimcoreEditmode: true },

	]
}

export default Config;