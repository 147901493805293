/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 23.03.2020.
 */
"use strict";

import gsap from "gsap/all";
import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import Events from "../fieldcode.events";
import * as Utils from "../utilities/Utilities";
import DKPagination from "../classes/Pagination";

class Features extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);

		this._itemWrapper = this._element.querySelector('.features__list');
		this._items = this._element.querySelectorAll('.features__item');
		this._itemActive = this._element.querySelector('.features__item--active');
		this._paginationElement = this._element.querySelector('.features__pagination');
		this._pagination = null;

		this._init();
	}

	//public

	//private

	_init() {
		this._element.dataset.init = true;

		if (this._items.length > 1 && this._itemWrapper) {
			if (this._paginationElement) {
				this._pagination = new DKPagination(this._paginationElement, this._element, this._app);
			}
		}

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		if (this._pagination) {
			this._element.addEventListener(Events.pagination.clicked, this._onPaginationItemClicked.bind(this));
		}
	}

	_goto(index) {
		let tl;
		if (this._isAnimating) return;

		this._items.forEach((item, idx) => {
			if (idx == index) {
				tl = gsap.timeline({
					onStart:() => {
						this._isAnimating = true;
					},
					onComplete: () => {
						this._itemActive.classList.remove('features__item--active');
						this._itemActive = item;
						this._itemActive.classList.add('features__item--active');
						this._isAnimating = false;
					}
				});

				tl
					.to(this._itemActive, {
						display: 'none',
						visibility: 'hidden',
						opacity: 0,
						duration: 0.3
					})
					.to(item, {
						display: 'grid',
						visibility: 'visible',
						opacity: 1,
						duration: 0.4
					}, );


			}
		});
	}

	_onPaginationItemClicked(evt) {
		let idx = evt.detail.idx;
		this._goto(idx);
	}
}

export default Features;
