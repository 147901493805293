/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 27.08.2020.
 */

"use strict";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import delegate from 'es6-delegate/delegate';
//import checkPasswordStrength from 'check-password-strength';
import { passwordStrength } from 'check-password-strength';

class Password extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._input = this._element.querySelector('.password__input');
		this._inputRepeat = this._element.querySelector('.password__input-repeat');
		this._toggle = this._element.querySelector('.password__icons');
		this._strengthIndicator = this._element.querySelector('.password__strength');
		this._checkIndicator = this._element.querySelector('.password__check');
		this._conditionElements = this._element.querySelectorAll('.password__condition');
		this._conditions = [];
		this._currentStrengthLevel = null;

		this._strength = null;
		this._contains = null;

		this._valid = true;

		this._init();

	}

	get inputElement() {
		return this._input;
	}

	/**
	 *
	 * @returns {Promise}
	 */
	validate() {
		return this._validate();
	}

	_init() {
		this._element.dataset.init = true;
		this._element.data = { instance: this };
		this._getConditions();

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		delegate('click', this._toggle, this._onClick.bind(this));
		delegate('keyup', this._input, this._onKeyUp.bind(this));
		if (this._inputRepeat) {
			delegate('keyup', this._inputRepeat, this._onKeyUpRepeat.bind(this));
		}
	}

	_checkPassword(onlyThisCondition) {
		let passwordCheckPassed;
		if (this._strengthIndicator) {
			this._strengthIndicator.classList.remove('password__strength--weak', 'password__strength--medium', 'password__strength--strong');
		}

		if (this._checkIndicator && !onlyThisCondition) {
			this._checkIndicator.classList.remove('password__check--failed', 'password__check--passed');
		}

		try {
			this._getStrength();
			passwordCheckPassed = this._checkConditions(onlyThisCondition);
			console.log("password checked", passwordCheckPassed, " only this condition", onlyThisCondition);


			if (this._strengthIndicator) {
				this._strengthIndicator.classList.add('password__strength--' + this._strength.value.toLowerCase());
			}

			if (this._checkIndicator && !onlyThisCondition) {
				this._checkIndicator.classList.add('password__check--' + (passwordCheckPassed ? 'passed' : 'failed'));
			}
		}
		catch (e) {
			console.error(e);
			this._input.value = '';
			this._inputRepeat.value = '';
		}
	}

	_checkConditions(onlyThisCondition) {
		let valid, passwordCheckPassed = true;
		if (this._conditions.length > 0) {
			this._conditions.forEach((condition, idx) => {
				valid = false;


				if (!onlyThisCondition || onlyThisCondition == condition.condition) {
					switch (condition.condition) {
						case 'length':
							if (this._strength.length >= parseInt(condition.conditionValue)) {
								valid = true;
							}
							break;
						case 'case':
							if (this._contains.includes('lowercase') && this._contains.includes('uppercase')) {
								valid = true;
							}
							break;
						case 'specialchar':
							if (this._contains.includes('symbol')) {
								valid = true;
							}
							break;
						case 'number':
							if (this._contains.includes('number')) {
								valid = true;
							}
							break;
						case 'equal':
							if (this._inputRepeat) {
								if (this._inputRepeat.value == this._input.value) {
									valid = true;
								}
							} else {
								valid = true;
							}
							break;
					}

					if (valid === false && condition.condition != 'equal') {
						passwordCheckPassed = false;
					}



					condition.valid = valid;
					if (condition.element) {
						if (valid) {
							condition.element.classList.add('password__condition--valid');
						} else {
							condition.element.classList.remove('password__condition--valid');
						}
					}
				}
			})
		}

		return passwordCheckPassed;
	}

	_getStrength() {
		let contain = [], inputValue = this._input.value, symbolFound = false;
		//https://github.com/deanilvincent/check-password-strength#readme
		this._strength = passwordStrength(this._input.value);

		console.log("pwd _strength", this._strength);

		this._strength.contains.forEach((cond, idx) => {
			contain.push(cond);
			if (cond == 'symbol') {
				symbolFound = true;
			}
		});

		//additional test because lib only check some special chars
		if (!symbolFound) {
			if (/[^a-zA-Z\d\s:]/.test(inputValue)) {
				contain.push('symbol');
			}
		}

		this._contains = contain;
	}

	_getConditions() {
		let conditions = this._element.dataset.conditions, condition;
		if (conditions) {
			conditions = conditions.split(',');
			conditions.forEach((conditionString, idx) => {
				condition = conditionString.split(':');

				this._conditions.push({
					element: this._element.querySelector('[data-condition="'+condition[0]+'"]'),
					condition: condition[0],
					conditionValue: condition[1],
					valid: false
				});

			})
		}
	}

	/**
	 *
	 * @param level
	 * @returns {Promise}
	 * @private
	 */
	_validate() {
		let valid = true, invalidConditions = [];
		return new Promise((resolve, reject) => {
			this._conditions.forEach((condition, idx) => {
				if (condition.valid === false) {
					valid = false;
					invalidConditions.push(condition.condition);
				}
			})

			if (valid) {
				resolve();
			}
			else {
				reject(invalidConditions);
			}
		})
	}

	_onClick(evt, element) {
		if (this._input.getAttribute('type') == 'password') {
			this._input.setAttribute('type', 'text');
			this._element.classList.remove('password--hidden');
		}
		else {
			this._input.setAttribute('type', 'password');
			this._element.classList.add('password--hidden');
		}
	}

	_onKeyUp(evt, element) {
		this._checkPassword();
	}
	_onKeyUpRepeat(evt, element) {
		this._checkPassword('equal');
	}

	/*
	_checkStrength() {
		/**
		 * Strong:
		 * (?=.*[a-z])	        The string must contain at least 1 lowercase alphabetical character
		 * (?=.*[A-Z])	        The string must contain at least 1 uppercase alphabetical character
		 * (?=.*[0-9])	        The string must contain at least 1 numeric character
		 * (?=.*[!@#$%^&*])	The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
		 * (?=.{8,})	        The string must be eight characters or longer
		 *
		 * Medium:
		 * nearly the same as the strong condition, except OR condition, no special chars, length 6
		 *

		const   strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})'),
			mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})');

		let value = this._input.value;


		this._strengthIndicator.classList.remove('password__strength--weak','password__strength--medium','password__strength--strong');

		if (value.length > 0) {
			if (strongRegex.test(value)) {
				this._strengthIndicator.classList.add('password__strength--strong');
				return 'strong';
			}
			else if (mediumRegex.test(value)) {
				this._strengthIndicator.classList.add('password__strength--medium');
				return 'medium';
			}
			else {
				this._strengthIndicator.classList.add('password__strength--weak');
			}
		}

		return 'weak';
	}*/
}

export default Password;