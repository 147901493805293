/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 20.09.2019.
 */
"use strict";

class Helper {

	constructor() {
		let self = this;
		const DOC_ELEMENT = document.documentElement;
		const DOC_BODY = document.body;
	}

	/**
	 * Get css var value
	 * @param element
	 * @param variableName
	 * @returns {string}
	 */
	static getCssVariableValue(element, variableName) {
		return getComputedStyle(element).getPropertyValue(variableName);
	}

	/**
	 * Get viewport width
	 * @returns {Number|number}
	 */
	static getViewportWidth() {
		return window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
	}

	/**
	 * Get viewport height
	 * @returns {Number|number}
	 */
	static getViewportHeight() {
		return window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
	}

	/**
	 * Get width of an element
	 * @param element || selector
	 * @returns {*}
	 */
	static getWidth(element, raw) {
		let target, w;
		if (element && element.nodeType == 1) {
			target = element;
		}
		else {
			target = document.querySelector(element);
		}

		if (!target) {
			target = document.querySelector('BODY');

		}
		w = window.getComputedStyle(target).width;
		if (raw) {
			return parseFloat(w);
		}
		return w;
	}

	/**
	 * Get height of an element
	 * @param element
	 * @returns {*}
	 */
	static getHeight(element, raw) {
		let target, h;
		if (element && element.nodeType == 1) {
			target = element;
		}
		else {
			target = document.querySelector(element);
		}

		if (!target) {
			target = document.querySelector('BODY');
		}
		h = window.getComputedStyle(target).height;
		if (raw) {
			return parseFloat(h);
		}
		return h;
	}

	/**
	 * Get scroll position
	 * @param axis
	 * @param target
	 * @returns {number}
	 */
	static getScrollPosition(axis, target) {
		if (!target) {
			if (!axis || axis == 'y') {
				return (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0);
			}
			else {
				return (window.pageXOffset || document.documentElement.scrollLeft) - (document.documentElement.clientLeft || 0);
			}
		}
		else {
			if (!axis || axis == 'y') {
				return target['scrollTop'] - (document.documentElement.clientTop || 0);
			}
			else {
				return target['scrollLeft'] - (document.documentElement.clientLeft || 0);
			}
		}
	}

	/**
	 * Get scroll position in percents
	 * @param axis
	 * @param target
	 * @param withoutViewport
	 * @returns {number}
	 */
	static getScrollPercent(axis, target, withoutViewport) {
		let st = 'scrollTop',sh = 'scrollHeight',sl = 'scrollLeft',sw = 'scrollWidth', ch = withoutViewport ? 0 : document.documentElement.clientHeight;

		if (!target) {
			if (!axis || axis == 'y') {
				st = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
				sh = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
				return (st) / ((sh) - ch) * 100;
			}
			else {
				target = document.documentElement || document.body;
			}


		}

		if (!axis || axis == 'y') {
			return (target[st]) / ((target[sh]) - ch) * 100;
		}
		else {
			return (target[sl]) / ((target[sw]) - ch) * 100;
		}

	}

	/**
	 * Get offset
	 * @param element
	 * @param target
	 * @returns {{top: *, left: *}}
	 */
	static getOffset(element, target) {
		let rect =      element.getBoundingClientRect(),
			scrollLeft,
			scrollTop;

		if (target !== undefined) {
			scrollLeft = target.scrollLeft;
			scrollTop = target.scrollTop;
		}
		else {
			scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
			scrollTop = window.pageYOffset || document.documentElement.scrollTop;

		}

		return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
	}

	/**
	 * Get viewport offset
	 * @param element
	 * @returns {{top: *, left: *}}
	 */
	static getViewportOffset(element) {
		let node = element;
		let left = 0;
		let top = 0;

		do {
			let styles = getComputedStyle(node);

			if (styles) {
				var position = styles.getPropertyValue('position');

				left -= node.scrollLeft;
				top -= node.scrollTop;

				if (/relative|absolute|fixed/.test(position)) {
					left += parseInt(styles.getPropertyValue('border-left-width'), 10);
					top += parseInt(styles.getPropertyValue('border-top-width'), 10);

					left += node.offsetLeft;
					top += node.offsetTop;
				}

				node = position === 'fixed' ? null : node.parentElement;
			} else {
				node = node.parentElement;
			}

		} while (node);

		return { left: left, top: top };
	}

	/**
	 * Force reflow
	 * @param element
	 * @returns {number}
	 */
	static reflow(element) {
		return element.offsetHeight;
	}

	static nl2br (str, is_xhtml) {
		// http://kevin.vanzonneveld.net
		// +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
		// +   improved by: Philip Peterson
		// +   improved by: Onno Marsman
		// +   improved by: Atli Þór
		// +   bugfixed by: Onno Marsman
		// +      input by: Brett Zamir (http://brett-zamir.me)
		// +   bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
		// +   improved by: Brett Zamir (http://brett-zamir.me)
		// +   improved by: Maximusya
		// *     example 1: nl2br('Kevin\nvan\nZonneveld');
		// *     returns 1: 'Kevin<br />\nvan<br />\nZonneveld'
		// *     example 2: nl2br("\nOne\nTwo\n\nThree\n", false);
		// *     returns 2: '<br>\nOne<br>\nTwo<br>\n<br>\nThree<br>\n'
		// *     example 3: nl2br("\nOne\nTwo\n\nThree\n", true);
		// *     returns 3: '<br />\nOne<br />\nTwo<br />\n<br />\nThree<br />\n'
		var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display

		return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
	}

	/**
	 *
	 * @param value
	 * @param reference
	 * @param round
	 * @returns {number}
	 */
	static calcPercent(value, referenceValue, round) {
		let cv = value / referenceValue * 100;
		if (round) {
			cv = Math.round(cv);
		}
		return cv;
	};

	/**
	 *
	 * @param percentValue
	 * @param target
	 * @returns {number}
	 */
	static calcPixelFromPercent(percentValue, target) {
		let scrollHeight, rv;
		target = target ? target : document.documentElement;
		if (typeof target != 'number') {
			scrollHeight = target.scrollHeight;
		}
		else {
			scrollHeight = target;
		}

		rv = Math.round(scrollHeight / 100 * percentValue)

		//console.log(rv, " > ", (rv - this.getViewportHeight()));
		//
		//if (rv > (rv - this.getViewportHeight())) {
		//	console.log("return rv minus vw", rv);
		//	rv -= this.getViewportHeight();
		//	console.log("minus rv", rv);
		//
		//}

		return rv;
	}

	/**
	 * Check if var is a function
	 * @param object
	 * @returns {boolean}
	 */
	static isFunction(object) {
		return typeof object === 'function';
		//return !!(object && object.constructor && object.call && object.apply);
	}
}

export default Helper;