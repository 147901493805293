/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 27.03.2020.
 */
"use strict";

import gsap from "gsap/all";

import * as Utils from "../../utilities/Utilities";

class SupportItem {
	constructor(element) {
		this._element = element;
		this._content = this._element.querySelector('.support__item-content');
		this._index = null;
		this._height = 0;
		this._isActive = false;
		this._init();
	}

	//public

	/**
	 *
	 * @returns {number}
	 */
	calculateHeight() {
		return this._calculateHeight();
	}

	/**
	 *
	 * @param display
	 * @returns {Promise}
	 */
	setDisplay(display) {
		return this._setDisplay(display);
	}

	//get / set

	get element() {
		return this._element;
	}

	get content() {
		return this._content;
	}

	get calculatedHeight() {
		if (this._height == 0) {
			return this._calculateHeight();
		}
		return this._height;
	}

	get index() {
		return this._index;
	}

	get isActive() {
		return this._isActive;
	}

	set isActive(isActive) {
		this._isActive = isActive
	}

	//private
	_init() {
		this._index = this._element.dataset.index;
		this._calculateHeight();
	}

	/**
	 *
	 * @returns {Promise<unknown>}
	 * @private
	 */
	async _calculateHeight() {
		await this._setDisplay('block');
		this._height = Utils.Helper.getHeight(this._element, true);
		await this._setDisplay('none');

		return Promise.resolve(this._height);
	}

	/**
	 *
	 * @param display
	 * @returns {Promise}
	 * @private
	 */
	_setDisplay(display) {
		gsap.set(this._element, {display: display});
		return Promise.resolve(1);
	}
}

export default SupportItem;