/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 13.10.2020.
 */


"use strict";

import gsap from "gsap/all";
import { CSSPlugin } from 'gsap/all';

import delegate from 'es6-delegate/delegate';
import DOMPurify from 'dompurify';

import BaseClass from "../../classes/BaseClass";
import Init from "../../classes/Init";
import CalculatorView from './CalculatorView';
import * as Utils from "../../utilities/Utilities";
import Pristine from 'pristinejs/dist/pristine';

class Calculator extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);

		this._calculator = this._element.querySelector('.calc');
		this._calculateButton = this._calculator.querySelector('[data-calculator-calc]');
		this._resetButton = this._calculator.querySelector('[data-calculator-reset]');

		this._sendResultsForm = this._element.querySelector('.form--calculator-results');
		this._sendResultsMailInput = this._sendResultsForm.querySelector('[name="email"]');
		this._sendResultsMailRepeatInput = this._sendResultsForm.querySelector('[name="email-repeat"]');
		this._sendResultsButton = this._sendResultsForm.querySelector('[data-calculator-sendmail]');

		this._hfpForm = this._element.querySelector('.form--calculator-hfp');
		this._hfpFormFirstnameInput = this._hfpForm.querySelector('[name="firstname"]');
		this._hfpFormLastnameInput = this._hfpForm.querySelector('[name="lastname"]');
		this._hfpFormMailInput = this._hfpForm.querySelector('[name="email"]');
		this._hfpFormMailRepeatInput = this._hfpForm.querySelector('[name="email-repeat"]');
		this._hfpFormPhoneInput = this._hfpForm.querySelector('[name="phone"]');
		this._hfpFormPhoneRepeatInput = this._hfpForm.querySelector('[name="phone-repeat"]');
		this._hfpFormMessageInput = this._hfpForm.querySelector('[name="message"]');

		this._summary = this._element.querySelector('.calculator__summary');
		this._summaryHeight = 0;
		this._validator = null;
		this._validatorHFP = null;

		this._views = {
			calculator: new CalculatorView(this._element.querySelector('.calculator__view--calculator'), true),
			intro: new CalculatorView(this._element.querySelector('.calculator__view--intro'), true),
			result: new CalculatorView(this._element.querySelector('.calculator__view--result'), false),
			mail: new CalculatorView(this._element.querySelector('.calculator__view--mail'), false),
			hfp: new CalculatorView(this._element.querySelector('.calculator__view--hfp'), false),
			hfpright: new CalculatorView(this._element.querySelector('.calculator__view--hfp-right'), false)
		}

		this._fields = {
			nou: this._element.querySelector('[name="nou"]'),
			cpu: this._element.querySelector('[name="cpu"]'),
			noe: this._element.querySelector('[name="noe"]')
		}

		this._values = {
			nou: 0,
			cpu: 0,
			noe: 0
		}

		this._period  = null;
		this._currency = null;

		this._currentState = 'initial';
		this._currentIndex = 0;

		this._init();
	}

	//public

	//private

	_init() {
		this._element.dataset.init = true;
		this._currency = this._element.querySelector('.button--active[data-calculator-currency]').dataset.calculatorCurrency;
		this._period = this._element.querySelector('.button--active[data-calculator-period]').dataset.calculatorPeriod;

		this._summaryHeight = gsap.getProperty(this._summary, 'height');
		gsap.set(this._summary, {
			opacity: 1,
			height: 0,
			rotateX: '-90deg',
			transformPerspective: 400,
			transformOrigin: 'top center',
			transformStyle: 'preserve-3d'
		});


		this._initValidators()
		this._attachEventsAndListeners();
	}

	_initValidators() {
		if (this._sendResultsForm) {
			this._validator = new Pristine(this._sendResultsForm, {
				classTo: 'form__group',
				errorClass: 'form__group--has-error',
				successClass: 'form__group--has-success',
				//errotTextParent: false
				errorTextParent: 'form__group',
				errorTextTag: 'div',
				errorTextClass: 'tooltip tooltip--half'
			});
		}

		if (this._hfpForm) {
			this._validatorHFP = new Pristine(this._hfpForm, {
				classTo: 'form__group',
				errorClass: 'form__group--has-error',
				successClass: 'form__group--has-success',
				//errotTextParent: false
				errorTextParent: 'form__group',
				errorTextTag: 'div',
				errorTextClass: 'tooltip tooltip--half'
			});
		}
	}

	_attachEventsAndListeners() {
		delegate('click', '[data-calculator-calc]', this._calculateView.bind(this));
		delegate('click', '[data-calculator-reset]', this._resetView.bind(this));
		delegate('click', '[data-calculator-sendmail]', this._mailView.bind(this));
		delegate('click', '[data-calculator-currency]', this._switchCurrency.bind(this));
		delegate('click', '[data-calculator-period]', this._switchPeriod.bind(this));

		//test
		//delegate('click', '[data-calculator-calc]', this._hfpView.bind(this));

		delegate(this._calculator, 'input', 'INPUT', this._collectValues.bind(this));
		Object.entries(this._fields).forEach(([key, element]) => {
			Utils.Form.inputFilter(element, (value) => {
				return /^\d*$/.test(value); //only positive int
			})
		});


		if (this._sendResultsForm) {
			this._sendResultsForm.addEventListener('submit', this._onSubmitSendResultsForm.bind(this));
		}

		if (this._hfpForm) {
			this._hfpForm.addEventListener('submit', this._onSubmitHFPForm.bind(this));
		}
	}

	_translate() {
		let te;
		fetch('/' +locale+ '/calculator/translate', {
			method: 'POST',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			redirect: 'follow',
			referrerPolicy: 'no-referrer',
			body: JSON.stringify({
				period: this._period
			})
		})
			.then(response => response.json())
			.then(json => {
				if (json.error === true ) {
					throw new Error(json.message)
				}

				Object.entries(json).map(([key, value]) => {
					if (typeof value === 'object' && value !== null) {
						Object.entries(value).map(([valueKey, translation]) => {
							te = this._element.querySelector('[data-calculator-translate="'+key+'.'+valueKey+'"]');
							if (te) {
								console.log("change: " + key+'.'+valueKey+ ' to ', translation);
								if (valueKey != 'placeholder') {
									te.innerHTML = DOMPurify.sanitize(translation);

								}
								else {
									te.setAttribute('placeholder', translation);
								}
							}
						});
					}
				});
			})
	}

	_calculate() {
		let te, hasSavings = true;

		return new Promise((resolve, reject) => {
			fetch('/' + locale + '/calculator/calculate', {
				method: 'POST',
				cache: 'no-cache',
				credentials: 'same-origin',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				redirect: 'follow',
				referrerPolicy: 'no-referrer',
				body: JSON.stringify({
					period: this._period,
					currency: this._currency,
					nou: this._values.nou,
					cpu: this._values.cpu,
					noe: this._values.noe
				})
			})
				.then(response => response.json())
				.then(json => {

					let valueElements = [];
					let valueValues = [];

					if (json.error === true) {
						throw new Error(json.message);
						reject(hasSavings, json.message);
					}
					Object.entries(json).map(([key, value]) => {
						te = this._element.querySelector('[data-calculator-value="' + key + '"]');
						if (key == 'sav' && value == false) {
							//fieldcode is not cheaper
							hasSavings = false;
							gsap.set(te, {opacity: 0});
							gsap.set(document.querySelector('[data-calculator-translate="sum.description"]'), {display: 'none'});
						} else if (key == 'sav' && value != false) {
							gsap.set(te, {opacity: 1});
							gsap.set(document.querySelector('[data-calculator-translate="sum.description"]'), {display: 'block'});
						}

						if (key != 'sum') {
							te = te.querySelector('DD');
						}

						valueElements.push(te);
						valueValues.push(value);
					});

					if (hasSavings) {
						valueElements.forEach((element, idx) => {
							element.innerHTML = DOMPurify.sanitize(valueValues[idx]);
						})
					}

					resolve(hasSavings);
				});
		});
	}

	_sendResults() {
		return new Promise((resolve, reject) => {
			fetch('/' +locale+ '/calculator/send', {
				method: 'POST',
				cache: 'no-cache',
				credentials: 'same-origin',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				redirect: 'follow',
				referrerPolicy: 'no-referrer',
				body: JSON.stringify({
					period: this._period,
					currency: this._currency,
					nou: this._values.nou,
					cpu: this._values.cpu,
					noe: this._values.noe,
					email: this._sendResultsMailInput.value,
					emailRepeat: this._sendResultsMailRepeatInput.value
				})
			})
				.then(response => response.json())
				.then(json => {
					if (json.error === true ) {
						reject(json.message);
					}

					resolve();
				});
		});
	}

	_sendHFPResults() {
		return new Promise((resolve, reject) => {
			fetch('/' +locale+ '/calculator/send-hfp', {
				method: 'POST',
				cache: 'no-cache',
				credentials: 'same-origin',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				redirect: 'follow',
				referrerPolicy: 'no-referrer',
				body: JSON.stringify({
					period: this._period,
					currency: this._currency,
					nou: this._values.nou,
					cpu: this._values.cpu,
					noe: this._values.noe,
					firstname: this._hfpFormFirstnameInput.value,
					lastname: this._hfpFormLastnameInput.value,
					email: this._hfpFormMailInput.value,
					emailRepeat: this._hfpFormMailRepeatInput.value,
					phone: this._hfpFormPhoneInput.value,
					phoneRepeat: this._hfpFormPhoneRepeatInput.value,
					message: this._hfpFormMessageInput.value
				})
			})
				.then(response => response.json())
				.then(json => {
					if (json.error === true ) {
						reject(json.message);
					}

					resolve();
				});
		});
	}

	_collectValues() {
		let value, calculationPossible = true;
		Object.entries(this._fields).forEach(([key, element]) => {
			value = parseInt(element.value);
			this._values[key] = value;

			if (!value || value <= 0) {
				calculationPossible = false;
			}
		});

		if (calculationPossible) {
			this._calculateButton.removeAttribute('disabled');
			//this._resetButton.removeAttribute('disabled');
		}
		else {
			this._calculateButton.setAttribute('disabled', true);
			//this._resetButton.setAttribute('disabled', true);
		}

	}



	_switchCurrency(evt, item) {
		evt.preventDefault();
		let currency = item.dataset.calculatorCurrency;
		if (currency != this._currency) {
			this._element.querySelector('[data-calculator-currency="'+this._currency+'"]').classList.remove('button--active');
			this._element.querySelector('[data-calculator-currency="'+currency+'"]').classList.add('button--active');
			this._currency = currency;
			this._calculate();
		}

	}

	_switchPeriod(evt, item) {
		evt.preventDefault();
		let period = item.dataset.calculatorPeriod, content;
		if (period != this._period) {
			this._element.querySelector('[data-calculator-period="'+this._period+'"]').classList.remove('button--active');
			this._element.querySelector('[data-calculator-period="'+period+'"]').classList.add('button--active');

			if (this._period == 'month') {
				//switch to yearly
				if (this._values.noe > 0) {
					this._fields.noe.value = parseInt(this._values.noe * 12);
				}
				if (this._values.cpu > 0) {
					this._fields.cpu.value = parseInt(this._values.cpu * 12);
				}
			}
			else {
				if (this._values.noe > 0) {
					this._fields.noe.value = parseInt(this._values.noe / 12);
				}
				if (this._values.cpu > 0) {
					this._fields.cpu.value = parseInt(this._values.cpu / 12);
				}
			}
			this._period = period;

			this._collectValues();
			this._translate();
			this._calculate();
		}
	}

	/**
	 *
	 * @param promises
	 * @returns {Promise}
	 * @private
	 */
	_moveViews(promises) {
		this._tweenInProgress = true;

		return new Promise((resolve) => {
			Promise
				.all(promises)
				.then((position) => {
					this._tweenInProgress = false;
					resolve();
				})
		})

	}

	_calculateView(evt, item) {
		let viewPromises;
		evt.preventDefault();
		evt.stopPropagation();

		this._calculate()
			.then(hasSavings => {
				console.log("has savings", hasSavings);
				if (hasSavings) {
					if (this._tweenInProgress || this._currentState == 'calculate' || this._currentIndex > 0) return;

					if (device == 'phone') {
						viewPromises = [
							this._views.calculator.animate(1, 'left', 0)
						];
					} else {
						viewPromises = [
							this._views.intro.animate(1, 'left', 0.2),
							this._views.result.animate(1, 'left'),
							this._views.mail.animate(1, 'left')
						];
					}

					this._moveViews(viewPromises)
						.then(() => {
							this._views.calculator.active = false;
							this._views.result.active = true;

							this._currentState = 'calculate'
							this._currentIndex++;
						})
						.finally(() => {
							//this._calculate()

							this._summaryView();
						});
				}
				else {
					this._hfpView(evt, item);
				}
			})
			.catch(errorMessage => {
				console.error(errorMessage);
			})
	}

	_mailView(evt, item) {
		let viewPromises;
		evt.preventDefault();
		evt.stopPropagation();

		if (this._tweenInProgress || this._currentState == 'sendmail' || this._currentIndex != 1) return;

		//hide email button
		gsap.to( this._sendResultsButton, { opacity: 0, duration: 0.2 });

		if (device == 'phone') {
			viewPromises = [
				this._views.result.animate(1, 'left', 0)
			];
		}
		else {
			viewPromises = [
				this._views.intro.animate(1, 'left'),
				this._views.calculator.animate(1, 'left'),
				this._views.result.animate(1,'left'),
				this._views.mail.animate(1,'left')
			];
		}

		this._moveViews(viewPromises)
			.then(() => {
				this._views.result.active = false;
				this._views.mail.active = true;

				this._currentState = 'sendmail'
				this._currentIndex++;
			});
	}


	_hfpView(evt, item) {
		let viewPromises;
		evt.preventDefault();
		evt.stopPropagation();

		viewPromises = [
			this._views.hfp.animate(4, 'left'),
			this._views.hfpright.animate(4, 'left'),
			//this._views.result.animate(1,'left'),
			//this._views.mail.animate(1,'left')
		];

		this._moveViews(viewPromises)
			.then(() => {
				this._views.result.active = false;
				this._views.mail.active = false;
				this._views.hfp.active = true;
				this._views.hfpright.active = true;

				this._currentState = 'hfp'
				this._currentIndex++;
			});

		if (this._tweenInProgress) return;
	}

	_summaryView(hide) {
		let rx = 0, h = 'auto';
		if (hide) {
			rx = '-90deg';
			h = 0;
		}

		return new Promise((resolve) => {
			gsap.to(this._summary, {
				height: h,
				rotateX: rx,
				duration: 0.6,
				ease: 'expo.inOut',
				onComplete: () => {
					resolve();
				}
			});
		})

	}


	_resetView(evt, item) {
		evt.preventDefault();
		evt.stopPropagation();

		Object.entries(this._fields).forEach(([key, element]) => {
			element.value = '';
		});
		this._collectValues();

		if (this._tweenInProgress || this._currentState == 'initial' || this._currentIndex === 0) return;


		this._moveViews([
			this._views.result.animate('initial'),
			this._views.mail.animate('initial'),
			this._views.calculator.animate('initial', false, 0),
			this._views.intro.animate('initial', false, 0),
			this._views.hfp.animate('initial', false, 0),
			this._views.hfpright.animate('initial', false, 0),

			this._summaryView(true)
		]).then(() => {
			this._currentState = 'initial'
			this._currentIndex = 0;
			gsap.set( this._sendResultsButton, { opacity: 1 });
		}).finally(() => {


		});
	}

	_onSubmitSendResultsForm(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		if (this._validator.validate()) {
			this._sendResults()
				.then(() => {
					this._views.mail.animateSubstep(0, 'hide').then(() => {
						this._views.mail.animateSubstep(1, 'show').then(() => {

						})
					})
				})
				.catch((error) => {
					console.log("error", error);
				})

		}
		else {
			console.log("error", this._validator.getErrors());
		}
	}

	_onSubmitHFPForm(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		if (this._validatorHFP.validate()) {
			this._sendHFPResults()
				.then(() => {
					this._views.hfpright.animateSubstep(0, 'hide').then(() => {
						this._views.hfpright.animateSubstep(1, 'show').then(() => {

						})
					})
				})
				.catch((error) => {
					console.log("error", error);
				})

		}
		else {
			console.log("error", this._validator.getErrors());
		}
	}


}

export default Calculator;
