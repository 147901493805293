/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 22.07.2020.
 */


import { gsap } from "gsap/all";
import delegate from "es6-delegate/delegate";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import Events from "../fieldcode.events";
import * as Utils from "../utilities/Utilities";


class Sidetoolbar extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._localStorage = window['localStorage'];

		this._banner = null;
		this._bannerOverlay = null;
		this._bannerWrapper = null;
		this._bannerVisible = false;
		this._bannerTimeline = null;
		this._bannerHideOnScroll = true;
		this._toolbarVisible = false;

		this._items = this._element.querySelectorAll('.side-banner__item');
		this._lastTopPos = null;

		this._init();
	}

	//public

	//get/set

	//private

	_init() {
		this._element.dataset.init = true;

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		window.addEventListener(Events.preload.finished, this._onPreloadFinished.bind(this));
		//window.addEventListener('throttledScroll', this._onOptimiziedScroll.bind(this));
		window.addEventListener(Events.layout.scroll.did, this._onOptimiziedScroll.bind(this));
		delegate(this._element,'click', '.side-toolbar__item', this._onClickItem.bind(this));

		delegate(document, 'click', '.side-banner__close', this._onClickBannerClose.bind(this));
		delegate(document, 'click', '.side-banner__overlay', this._onClickBannerOverlay.bind(this));
	}

	_toggleToolbar(delay) {
		let x = 0;
		if (this._toolbarVisible) {
			x = 100;
		}

		return new Promise((resolve, reject) => {
			gsap.to(this._element, {
				x: x,
				duration: 0.125,
				delay: delay === undefined ? 1 : delay,
				onComplete: () => {
					this._toolbarVisible = !this._toolbarVisible;
					resolve();
				},
			});
		})
	}

	_toggleBanner() {
		return new Promise((resolve, reject) => {
			if (this._bannerVisible && this._bannerTimeline) {
				//console.log("banner reverse");
				this._bannerTimeline.eventCallback('onReverseComplete', () => {
					this._bannerVisible = false;
					resolve();
				})
				this._bannerTimeline.reverse()
			}
			else {
				this._bannerTimeline = new gsap.timeline({
					paused: true,
					onStart: () => {
					},
					onComplete: () => {
						this._bannerVisible = true;
						resolve();

					}
				});

				this._bannerTimeline
					.set(this._banner, {
						display: 'block'
					})
					.to(this._bannerOverlay, {
						opacity: 1,
						duration: 0.125
					})
					.to(this._bannerWrapper, {
						x: 0,
						right: '2rem',
						duration: 0.125,
						ease: 'power3.inOut'
					});

				this._bannerTimeline.play();
			}
		})
	}

	_selectBanner(name) {
		this._banner = document.querySelector('.side-banner[data-banner="'+name+'"]');
		if (this._banner) {
			//console.log("sidetoolbar clicked", this._banner, element);
			this._bannerOverlay = this._banner.querySelector('.side-banner__overlay');
			this._bannerWrapper = this._banner.querySelector('.side-banner__wrapper');
		}
	}

	_closeBanner() {
		if (this._localStorage && !this._localStorage.getItem('flc-sidetoolbar-shown')) {
			this._localStorage.setItem('flc-sidetoolbar-shown', 'true');
		}

		this._toggleBanner().then(() => {
			this._toggleToolbar(0);
			this._bannerTimeline = null;
		})
	}

	_onClickItem(evt, element) {
		if (evt) evt.preventDefault();

		this._selectBanner(element.dataset.banner);
		if (this._banner) {
			this._toggleToolbar(0).then(() => {
				this._toggleBanner();
			});
		}
	}

	_onClickBannerClose(evt, element) {
		if (evt) evt.preventDefault();
		this._closeBanner();
	}

	_onClickBannerOverlay(evt, element) {
		if (evt) evt.preventDefault();
		this._closeBanner();
	}

	_onPreloadFinished(evt) {
		if (this._localStorage && this._localStorage.getItem('flc-sidetoolbar-shown')) {
			this._toggleToolbar();
		}
		else {
			this._selectBanner('login');
			if (this._banner) {
				this._toggleBanner();
			}
		}
	}

	_onOptimiziedScroll(evt) {
		let topPos, yPercentScrolled = Utils.Helper.getScrollPercent('y');

		//if (this._bannerHideOnScroll && evt.detail.scrollTop > 50) {
		//	console.log("hide banner on scroll");
		//	this._bannerHideOnScroll = false;
		//	this._closeBanner();
		//}

		if (evt.detail.scrollTop > 100 && yPercentScrolled < 90) {
			topPos = '85%';
		}
		else {
			topPos = '50%';
		}

		if (this._lastTopPos != topPos) {
			gsap.to(this._element, {
				top: topPos,
				duration: 0.575,
				delay: 0.2,
				ease: 'power3.inOut'
			});

			this._lastTopPos = topPos;
		}

	}
}

export default Sidetoolbar;