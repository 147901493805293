/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 01.10.2019.
 */
import imagesLoaded from "imagesloaded/imagesloaded";
import Events from "../../fieldcode.events";
import * as Utils from "../../utilities/Utilities";

class PreloadBase {
	constructor(element, referenceElement, app, preloadThis) {
		this._element = element;
		this._referenceElement = referenceElement;
		this._app = app;

		this._typeName = '';
		this._preloaded = preloadThis ? false : true;

		if (preloadThis) {
			this._init();
		}
	}

	//get
	get preloaded() {
		return this._preloaded;
	}

	/**
	 *
	 * @returns {string}
	 */
	get typeName() {
		return this._typeName;
	}

	/**
	 *
	 * @returns {boolean}
	 */
	get isPreloaded() {
		return this._preloaded;
	}

	/**
	 *
	 * @param typeName
	 */
	set typeName(typeName) {
		this._typeName = typeName;
	}

	/**
	 *
	 * @param isPreloaded
	 */
	set isPreloaded(isPreloaded) {
		this._preloaded = isPreloaded;
	}

	//public

	preload() {
		this._preload();
	}

	//private
	_init() {
		this._element.classList.add('preloading');
		this._register();
		this._attachEventsAndListeners();

	}

	_attachEventsAndListeners() {
		window.addEventListener(Events.preload.begin, this._onPreloadBegin.bind(this));
		//window.addEventListener(Events.preload.xhr, this._onPreloadXhr.bind(this));
		window.addEventListener(Events.layout.mutation.oberserved, this._onPreloadBegin.bind(this));

	}

	_onPreloadBegin() {
		this._preload();
	}

	_onPreloadXhr() {
		this._preload();
	}

	_onPreloadFinished() {

	}

	_register() {
		Utils.DispatchEvent.dispatch(Events.preload.register, {
			instance: this,
			element: this._element
		});
	}

	_preload() {

		//console.log(this._element);
		//this._element.classList.remove('preloading');
		//this._preloaded = true;

		let imgLoad = imagesLoaded(this._element, { background: true }, () => {
			this._element.classList.remove('preloading');
			this._preloaded = true;

			Utils.DispatchEvent.dispatch(Events.preload.unregister, {
				instance: this,
				element: this._element
			});
		});
		//
		//console.log(imgLoad);
		//
		//imgLoad.on( 'always', function( instance ) {
		//	console.log('ALWAYS - all images have been loaded');
		//});
		//
		//imgLoad.on( 'fail', function( instance ) {
		//	console.log('FAIL - all images loaded, at least one is broken', instance);
		//});
		//
		//imgLoad.on( 'progress', function( instance, image ) {
		//	var result = image.isLoaded ? 'loaded' : 'broken';
		//	console.log( 'image is ' + result + ' for ' + image.img.src );
		//});
	}



}

export default PreloadBase;