/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 01.06.2021.
 */
/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 06.04.2020.
 */


"use strict";

import { gsap } from "gsap/all";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import Events from '../fieldcode.events';

class Countup extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this.animateComplete = false;
		this.valueElement = this._element.querySelector('.countup__value');
		this.originValue = null;
		this.targetValue = null;

		this.labelElement = this._element.querySelector('.numbers__label');

		this._init();
	}

	//public

	//get/set

	//private

	_init() {
		this._element.dataset.init = true;
		this._app.handlers.intersection.attach(this._element);

		this.originValue = parseInt(this.valueElement.innerText);
		this.targetValue = parseInt(this.valueElement.dataset.targetValue);

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		this._element.addEventListener(Events.inview.in, this._onInViewIn.bind(this));
	}

	_onInViewIn(evt) {
		if (this.animateComplete) return;

		let valueHolder = {
			value: this.originValue
		}

		gsap.to(valueHolder, {
			duration: 1.6,
			delay: 0.2 + Math.random(),
			value: this.targetValue,
			snap: {
				value: 1
			},
			ease: 'quad.inOut',
			onUpdate: () => {
				//console.log(valueHolder);
				this.valueElement.innerHTML = valueHolder.value
			},
			onComplete: () => {
				this.animateComplete = true;
			}
		});
	}
}

export default Countup;