/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 22.03.2020.
 */


"use strict";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import Events from "../fieldcode.events";

class Animation extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._viewElement = this._element.querySelector('.animation__view');
		this._lottie = null;
		this._animation = null;
		this._path = null;
		this._autoplay = false;
		this._loop = false;
		this._speed = 1;
		this._actionOnInView = false;
		this._name = null;

		this._init();
	}

	//get / set;

	/**
	 *
	 * @returns {null} | animation
	 */
	get animation() {
		return this._animation;
	}

	//public
	/**
	 *
	 * @returns {*}
	 */
	load() {
		return this._load();
	}

	//private

	_init() {
		if (window.lottie) {
			this._lottie = window.lottie;
		}
		this._element.dataset.init = true;
		this._actionOnInView = this._element.dataset.onIvo != 'false' ? true : false;
		this._autoplay = this._element.dataset.autoplay == 'true' ? true : false;
		this._loop = this._element.dataset.loop == '1' ? true : false;
		this._name = this._element.dataset.name;
		this._path = this._element.dataset.path;
		this._speed = parseFloat(this._element.dataset.speed);


		this._app.handlers.intersection.attach(this._element, );
		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		if (this._actionOnInView) {
			this._app.handlers.intersection.attach(this._element);
			this._element.addEventListener(Events.inview.in, this._onInViewIn.bind(this));
			this._element.addEventListener(Events.inview.out, this._onInViewOut.bind(this));
		}

	}

	_onInViewIn(evt) {
		if (this._animation === null) {
			this._load();
		}

		try {
			this._animation.play();
		}
		catch (e) {
			console.error(e);
		}
	}

	_onInViewOut(evt) {
		try {
			this._animation.stop();
		}
		catch (e) {
			console.error(e);
		}

	}

	/**
	 *
	 * @returns {null} | {object}
	 * @private
	 */
	_load() {
		if (!this._lottie) return null;

		try {
			this._animation = this._lottie.loadAnimation({
				container: this._viewElement, // the dom element that will contain the animation
				renderer: 'svg',
				loop: this._loop,
				autoplay: this._autoplay,
				name: this._name,
				path: this._path // the path to the animation json
			});
			this._animation.setSpeed(this._speed);
		}
		catch (e) {
			console.error(e);
		}

		return this._animation;
	}
}

export default Animation;