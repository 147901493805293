/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 15.10.2019.
 */


"use strict";

import delegate from "es6-delegate/delegate";
import { gsap } from "gsap/all";

import Events from "../../fieldcode.events";


class AccordionItem {
	constructor(element) {
		this._element = element;
		this._header = this._element.querySelector('.accordion__header');
		this._content = this._element.querySelector('.accordion__content');
		this._isOpen = false;
		this._tweenInProgress = false;
		this._timeline = null;

		this._init();
	}

	//public

	toggle() {
		this._toggle();
	}

	// get / set

	get element() {
		return this._element;
	}

	get isOpen() {
		return this._isOpen;
	}

	set isOpen(isOpen) {
		this._isOpen = isOpen;
	}

	//private
	_init() {
		this._initTL();

		this._attachEventsAndListeners();
	}

	_initTL() {
		this._timeline = gsap.timeline({
			paused: true,
			onStart: () => {
				this._tweenInProgress = true;
				this._element.classList.add('accordion__item--open');
			},
			onComplete: () => {
				this._isOpen = true;
				this._tweenInProgress = false;
			},
			onReverseComplete: () => {
				this._timeline.kill();
				this._isOpen = false;
				this._tweenInProgress = false;
				this._element.classList.remove('accordion__item--open');
				//TweenMax.set(this._content, {clearProps: 'all'});
			}
		});
		this._timeline
			.set(this._content, {
				display: 'block'
			})
			.from(this._content, 0.35, {
				height: 0,
				immediateRender: false,
				ease: 'expo.inOut'
			});
	}

	_attachEventsAndListeners() {
		delegate('click', this._header, this._toggle.bind(this));
		window.addEventListener(Events.layout.resize.did, this._onResizeDid.bind(this));
	}

	_onResizeDid() {
		if (this._timeline) {
			//this._timeline.reverse();
			this._isOpen = false;
			this._timeline.progress(0);
			this._timeline.kill();
			gsap.set(this._content, {clearProps: 'all'});
			this._initTL();


		}
	}

	_toggle(evt, item) {
		if (evt) evt.preventDefault();
		if (this._tweenInProgress) return;

		if (this._isOpen) {
			this._timeline.reverse();
		}
		else {
			this._timeline.play();
		}
	}
}

export default AccordionItem;