/**
 * project: Pimcore
 * Created by devkitchen on 04.08.2017.
 */

"use strict";

class DispatchEvent {
	/**
	 * dispatch custom event
	 * @param name
	 * @param data
	 * @param target
	 * @param bubbles
	 * @param cancable
	 * @param debug
	 */
	static dispatch(name, data = {}, target = window, bubbles = true, cancable = false, debug = false) {
		try {
			if (debug) {
				console.log("dispatch:", name, data, target, 'bubbles: ' + bubbles, 'cancel: ' + cancable);
			}

			if (!name) {
				throw new Error('No event name defined');
			}

			target.dispatchEvent(new CustomEvent(name, {
				detail: data,
				bubbles: bubbles,
				cancelable: cancable
			}));
		}
		catch (e) {
			console.error(e, name, data, target);
		}
	}
}

export default DispatchEvent;
//export default new DispatchEvent();