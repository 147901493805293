
"use strict";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import Events from '../fieldcode.events';
import {gsap} from 'gsap/all';

class Pricingcompare extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._countingElements = this._element.querySelectorAll('.pricingcompare__counting');
		this._init();
	}

	_init() {
		this._element.dataset.init = true;

		if (this._countingElements) {
			this._countingElements.forEach((element, idx) => {
				this._app.handlers.intersection.attach(element);
				let prefix, number;
				if (/^\d+$/.test(element.innerText)) {
					prefix = '';
					number = element.innerText;
				}
				else {
					prefix = element.innerText.replace(/[0-9]/g, '') + ' ';
					number = element.innerText.replace( /^\D+/g, '');
				}

				element.dataset.prefix = prefix;
				element.dataset.targetValue = parseInt(number);
				element.innerText = prefix + '0';
			})
		}

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		if (this._countingElements) {
			this._countingElements.forEach((element, idx) => {
				element.addEventListener(Events.inview.in, this._onInViewIn.bind(this));
			});
		}
	}

	_onInViewIn(evt) {
		let element = evt.detail.element;
		if (element.dataset.animation == 'complete') return;

		let targetValue = parseInt(element.dataset.targetValue);
		let prefix = element.dataset.prefix;

		let valueHolder = {
			value: 0
		}

		gsap.to(valueHolder, {
			duration: 1.6,
			delay: 0.2 + Math.random(),
			value: targetValue,
			snap: {
				value: 1
			},
			ease: 'quad.inOut',
			onUpdate: () => {
				//console.log(valueHolder);
				element.innerHTML = prefix + new Intl.NumberFormat().format(valueHolder.value);
			},
			onComplete: () => {
				element.dataset.animation = 'complete';
			}
		});
	}
}

export default Pricingcompare;