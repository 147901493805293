/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 31.03.2020.
 */

"use strict";

import { gsap } from "gsap/all";
import scrollLock from "scroll-lock";
import delegate from "es6-delegate/delegate";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import Events from "../fieldcode.events";


class Sharebox extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._container = this._element.querySelector('.sharebox__container');
		this._items = this._element.querySelectorAll('.sharebox__item');

		this._tl = null;
		this._isVisible = false;
		this._isAnimating = false;
		this._init();
	}

	//public

	//get/set

	//private

	_init() {
		this._element.dataset.init = true;

		this._tl = gsap.timeline({
			paused: true,
			onStart: () => {
				this._isAnimating = true;
			},
			onComplete: () => {
				this._isAnimating = false;
				this._isVisible = true;
			},
			onReverseComplete: () => {
				this._isAnimating = false;
				this._isVisible = false;
			}
		});

		this._tl
			.set(this._element, { display: 'flex' })
			.to(this._element, {
				opacity: 1,
				duration: 0.4
			})
			.fromTo(this._container, {
				opacity: 0,
				y: '-50%'
			}, {
				opacity: 1,
				y: 0,
				duration: 0.4
			}, '-=0.2')
			.fromTo(this._items, {
				opacity: 0
			}, {
				opacity: 1,
				stagger: 0.1,
				duration: 0.1
			}, '-=0.2');

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		delegate('click', this._element, this._onClickBackground.bind(this));
		delegate('click', '[data-share]', this._onClick.bind(this));
		delegate(this._element,'click', '.sharebox__item', this._onClickItem.bind(this));
	}

	_show() {
		if (this._isAnimating) return;
		this._tl.timeScale(1);
		this._tl.play();
	}

	_hide() {
		this._tl.pause();
		this._tl.timeScale(2);
		this._tl.reverse();
	}

	_onClickItem(evt, element) {
		this._hide();
	}

	_onClick(evt, element) {
		evt.preventDefault();

		if (this._isVisible) {
			this._hide();
		}
		else {
			this._show();
		}
	}

	_onClickBackground(evt, element) {
		if (evt.target == element) {
			this._hide();
		}
	}


}

export default Sharebox;