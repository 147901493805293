/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 09.06.2021.
 */

"use strict";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import delegate from 'es6-delegate/delegate';

class Featurehighlights extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._items = this._element.querySelectorAll('.featurehighlights__item');
		this._activeIndex = 0;
		this._init();
	}

	_init() {
		this._element.dataset.init = true;

		this._attachEventsAndListeners();

	}

	_attachEventsAndListeners() {
		if (window.device == 'desktop') {
			delegate(this._element, 'mouseenter', '.featurehighlights__item', this._onMouseOver.bind(this));
			delegate('mouseleave', this._element, this._onMouseOut.bind(this));
		}
		else {
			delegate(this._element, 'click', '.featurehighlights__item', this._onClick.bind(this));
		}
	}

	_onClick(evt, target) {
		if (target.getAttribute('href') == '#') {
			if (evt) evt.preventDefault();
			let index = parseInt(target.dataset.index);
			if (index == this._activeIndex) {
				index = 0;
			}
			this._switchImage(index);

			this._items.forEach((item, idx) => {
				item.classList.remove('featurehighlights__item--active');
				if (item.dataset.index == index) {
					item.classList.add('featurehighlights__item--active');
				}
			})
		}
	}

	_switchImage(index) {
		const activeImage = this._element.querySelector('.featurehighlights__image[data-index="' + this._activeIndex + '"]');
		const image = this._element.querySelector('.featurehighlights__image[data-index="' + index + '"]');

		let realImage = null;
		if (image) {
			realImage = image.querySelector('.image');
		}

		if (image && activeImage && realImage) {
			activeImage.classList.remove('featurehighlights__image--active');
			image.classList.add('featurehighlights__image--active');
			this._activeIndex = index;
		}
	}

	_onMouseOver(evt, target) {
		const index = parseInt(target.dataset.index);
		this._switchImage(index);

	}

	_onMouseOut(evt, target) {
		const index = 0;
		this._switchImage(index);

	}
}

export default Featurehighlights;